import React from 'react';
import { AdminTable } from '../../components/AdminTable/AdminTable';
import { Heading } from '../../components/Theme/Theme';

export function Admins() {
  return (
    <>
      <Heading>Admins</Heading>
      <div>Here you can find all the admin users of The Body Coach program</div>

      <AdminTable />
    </>
  );
}
