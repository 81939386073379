export type ThemeColors = {
  inspireCobalt: string;
  stimulateInk: string;
  boostAqua: string;
  recoveryBlush: string;
  vigourZest: string;
  white: string;
  grey: string;
  bodyCopy: string;
  bodyCopyLight: string;
  warningRed: string;
};

export const colors: ThemeColors = {
  inspireCobalt: '#0053C7',
  stimulateInk: '#18335B',
  boostAqua: '#00cfda',
  recoveryBlush: '#f6c9c7',
  vigourZest: '#d7fd8c',
  white: '#fff',
  grey: '#BDBDBD',
  bodyCopy: '#393939',
  bodyCopyLight: '#9B9898',
  warningRed: '#DA1E28',
};
