import { CacheInvalidationDetail } from '../../types/adminPortalApiSchema';
import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';

export async function invalidateCache(): Promise<{
  data: { invalidateCache: CacheInvalidationDetail };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    invalidateCache: CacheInvalidationDetail;
    errors: [{ message: string }];
  }>(
    gql`
      mutation InvalidateCache {
        invalidateCache {
          id
          timestamp
        }
      }
    `
  );
}
