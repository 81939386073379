import { RecipePackSummary } from '../types/adminPortalApiSchema';
import { queryAPI } from '../utils/queryApi';
import gql from 'fake-tag';

export async function fetchRecipePacks(): Promise<{
  data: { recipePacks: RecipePackSummary[] };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    recipePacks: RecipePackSummary[];
    errors: [{ message: string }];
  }>(
    gql`
      query GetRecipePacks {
        recipePacks {
          id
          recipeCount
          snackCount
          generalCount
          refuelCount
          sequence
          suitability
          recipePackName
        }
      }
    `
  );
}
