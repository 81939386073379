import {
  StructuredListCell,
  StructuredListWrapper,
} from 'carbon-components-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { RecipePackSummaryInterface } from '../../types/adminPortalApiSchema';
import { BCStructuredList } from '../BCStructuredList/BCStructuredList';
import { SaladIcon } from '../Icons/Salad';
import { Title } from '../Theme/Theme';

export const RecipePackHeader = ({
  summary,
  isHealthy,
}: {
  summary: RecipePackSummaryInterface;
  isHealthy: boolean;
}): ReactElement => {
  const statusEmoji = isHealthy === true ? '✅' : '🚫';

  let readableSuitability: string;
  switch (summary.suitability) {
    case 'MIXED':
      readableSuitability = 'Mixed';
      break;
    case 'PESCATARIAN':
      readableSuitability = 'Pescatarian';
      break;
    case 'VEGAN':
      readableSuitability = 'Vegan';
      break;
    case 'VEGETARIAN':
      readableSuitability = 'Vegetarian';
      break;
  }

  return (
    <Wrapper>
      <StyledSaladIconWrapper>
        <SaladIcon />
      </StyledSaladIconWrapper>
      <HeadingWrapper>
        <Title>{`${readableSuitability} Pack ${summary.sequence}`}</Title>
        <PackInfo>
          <Light>{`Contentful ID:`}</Light>
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://app.contentful.com/spaces/450abldm8aui/entries/${summary.id}`}
            >
              {summary.id}
            </a>
          </span>
        </PackInfo>
      </HeadingWrapper>
      <DetailsWrapper>
        <DetailsContentWrapper>
          <LeftStructuredListWrapper
            style={{ width: '100%' }}
            ariaLabel="Structured list"
          >
            <BCStructuredList
              header="Recipe Pack Details"
              params={[
                { text: 'Recipe count', value: summary.recipeCount },
                { text: 'Status', value: statusEmoji },
              ]}
            />
          </LeftStructuredListWrapper>
        </DetailsContentWrapper>
      </DetailsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PackInfo = styled.p`
  margin-bottom: 10px;
  font-size: 1.1rem;
`;

const Light = styled.span`
  margin-right: 5px;
  color: ${(props): string => props.theme.bodyCopyLight};
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DetailsContentWrapper = styled.div`
  display: flex;
`;

const LeftStructuredListWrapper = styled((props) => (
  <StructuredListWrapper {...props} />
))``;

const StyledSaladIconWrapper = styled.div`
  margin-right: 10px;
  margin-top: 2px;
`;
