import React, { ReactElement, useEffect, useState } from 'react';
import { Routes } from './Router';
import { Auth, Hub } from 'aws-amplify';
import { Loader } from './components/Loader/Loader';

export function AuthHandler(): ReactElement {
  const [isLoadingAuthState, setIsLoadingAuthState] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          setIsLoggedIn(true);
          setIsLoadingAuthState(false);
        })
        .catch(() => {
          setIsLoggedIn(false);
          setIsLoadingAuthState(false);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signOut') {
        setIsLoggedIn(false);
        setIsLoadingAuthState(false);
      }
      if (data.payload.event === 'signIn') {
        setIsLoggedIn(true);
        setIsLoadingAuthState(false);
      }
    });
  }, []);

  if (isLoadingAuthState) return <Loader />;

  return <Routes isLoggedIn={isLoggedIn} />;
}
