import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HeaderMenu, HeaderMenuItem } from 'carbon-components-react';
import { StyledHeaderNavigation } from '../UserAuth/UserAuth';

export function Nav() {
  return (
    <StyledHeaderNavigation aria-label="User dropdown">
      {/*Clients*/}
      <HeaderMenu
        aria-label={'Clients'}
        menuLinkName={'Clients'}
        className="navDropdown"
      >
        <HeaderMenuItem href="/clients" className="navItem">
          Client list
        </HeaderMenuItem>
        {/*<HeaderMenuItem href="/transformations" className="navItem">*/}
        {/*  Transformations finder*/}
        {/*</HeaderMenuItem>*/}
        <HeaderMenuItem
          href="https://thebodycoach.zendesk.com/agent/dashboard"
          className="navItem"
          rel="noopener noreferrer"
        >
          Support Tickets
        </HeaderMenuItem>
      </HeaderMenu>

      {/*Content*/}
      <HeaderMenu
        aria-label={'Content'}
        menuLinkName={'Content'}
        className="navDropdown"
      >
        <HeaderMenuItem
          href="https://app.contentful.com/spaces/450abldm8aui"
          className="navItem"
          rel="noopener noreferrer"
        >
          Contentful
        </HeaderMenuItem>
        <HeaderMenuItem
          href="https://studio.brightcove.com/products/videocloud/home"
          className="navItem"
          rel="noopener noreferrer"
        >
          Brightcove
        </HeaderMenuItem>
        <HeaderMenuItem href="/content" className="navItem">
          Status Checks
        </HeaderMenuItem>
      </HeaderMenu>

      {/*App*/}
      <HeaderMenu
        aria-label={'App'}
        menuLinkName={'App'}
        className="navDropdown"
      >
        {/*<HeaderMenuItem*/}
        {/*  href="MISSING_URL"*/}
        {/*  className="navItem"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  App store connect*/}
        {/*</HeaderMenuItem>*/}
        <HeaderMenuItem
          href="https://analytics.google.com/analytics/web/#/p232302320/reports/home?params=_u..nav%3Dga1-experimental"
          className="navItem"
          rel="noopener noreferrer"
        >
          Google analytics
        </HeaderMenuItem>
      </HeaderMenu>

      {/*Admins*/}
      <HeaderMenuItem href="/admin" className="navItem">
        Admins
      </HeaderMenuItem>
    </StyledHeaderNavigation>
  );
}
