import React, { ReactElement } from 'react';

export const UserIcon = (): ReactElement => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7493 0.969681C10.4768 0.969681 0.485107 10.9653 0.485107 23.2424C0.485107 35.5195 10.4768 45.5151 22.7493 45.5151C35.0215 45.5151 45.0306 35.5195 45.0306 23.2424C45.0306 10.9653 35.0215 0.969681 22.7493 0.969681ZM22.7493 3.74509C33.519 3.74509 42.2563 12.4683 42.2563 23.2424C42.2563 27.7002 40.7469 31.8065 38.2334 35.09C36.4329 29.9933 32.537 26.6557 28.0724 25.2026C30.0356 23.5735 31.2976 21.0711 31.2976 18.2987C31.2976 13.4503 27.4785 9.46939 22.7493 9.46939C18.0199 9.46939 14.2181 13.4503 14.2181 18.2987C14.2181 21.0711 15.4801 23.5735 17.4432 25.2026C12.9873 26.6581 9.08603 29.9933 7.2822 35.09C4.7688 31.8065 3.25947 27.7002 3.25947 23.2424C3.25947 12.4683 11.9793 3.74509 22.7493 3.74509H22.7493ZM22.7493 12.2448C25.9234 12.2448 28.5233 14.8864 28.5233 18.2987C28.5233 21.6692 25.9471 24.3407 22.8013 24.3699C18.8546 24.3932 17.0179 21.1282 16.9923 18.2987C16.8978 14.6461 19.7792 12.2852 22.7493 12.2448ZM22.7493 27.1454C29.6685 27.213 34.7448 32.3782 36.066 37.4837C32.582 40.7444 27.8995 42.7397 22.7493 42.7397C17.6077 42.7397 12.9317 40.7519 9.44978 37.5011C11.1232 30.4971 16.8859 27.1498 22.7493 27.1454H22.7493Z"
        fill="#90A4C5"
      />
    </svg>
  );
};
