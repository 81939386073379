import { DataTable } from 'carbon-components-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Client } from '../../types/adminPortalApiSchema';
import { capitalize, isLastItemInArray } from '../../utils/utils';
import { StyledButton } from '../Button/Button';
import { HEADINGS } from './utils';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

export type ExtendedClient = Client & { userId?: string };
const ClientDataTable = ({
  rows,
  submitSucceeded,
}: {
  rows: ExtendedClient[];
  submitSucceeded: boolean;
}): ReactElement => {
  if (!submitSucceeded) {
    return <></>;
  }

  if (rows.length === 0) {
    return <NoResultText>No result</NoResultText>;
  }

  const userIds = rows.map((row) => row.userId);
  return (
    <DataTable
      headers={HEADINGS}
      locale="en"
      rows={rows}
      render={({ rows, headers, getHeaderProps }) => (
        <TableContainerWrapper>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })} key={header.key}>
                    {capitalize(header.header.toString())}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const userId = userIds[index];
                return (
                  <TableRow key={index}>
                    {row.cells.map((cell, i, arr) => {
                      return (
                        <TableCell key={cell.id}>
                          <StyledText>{cell.value}</StyledText>
                          {isLastItemInArray(arr, i) && (
                            <StyledButton
                              href={`/clients/${userId}`}
                              kind="secondary"
                              size="default"
                              tabIndex={0}
                              type="button"
                            >
                              View Details
                            </StyledButton>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainerWrapper>
      )}
    />
  );
};

const TableContainerWrapper = styled((props) => <TableContainer {...props} />)`
  margin-top: 50px;
`;

const NoResultText = styled.span`
  margin-top: 50px;
  display: block;
`;

const StyledText = styled.span`
  display: flex;
`;

export default ClientDataTable;
