import { ExerciseAbility, UserCycleStat } from '../types/adminPortalApiSchema';
import { ExerciseAbilityForEmail } from '../types/global';

export const roundTo2DP = (val: number): number => {
  return Math.round(val * 100) / 100;
};

export function isUUID(str: string): boolean {
  if (str[0] === '{') {
    str = str.substring(1, str.length - 1);
  }
  const regexGuid = /^({)?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(})?$/gi;
  return regexGuid.test(str);
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const last = <T>(arr: T[]): T => arr[arr.length - 1];
export const isLastItemInArray = <T>(arr: T[], i: number): boolean =>
  i === arr.length - 1;

export const orderCyclesByCreatedAtDate = (
  cycles: UserCycleStat[]
): UserCycleStat[] => {
  return cycles?.sort(({ cycleCreatedAt: a }, { cycleCreatedAt: b }) => {
    const firstTime = new Date(a).getTime();
    const secondTime = new Date(b).getTime();
    const equal = firstTime === secondTime;
    const greater = firstTime < secondTime;
    return equal ? 0 : greater ? 1 : -1;
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const keys = <O extends object>(obj: O): Array<keyof O> =>
  Object.keys(obj) as Array<keyof O>;

//ToDo - need just whilst we wait to rename enums
export const convertAbilityForEmail = (
  ability: ExerciseAbility
): ExerciseAbilityForEmail => {
  return ability === 'LIFESTYLE' ? 'ADVANCED+' : ability;
};

//ToDo - need just whilst we wait to rename enums
export const convertFromEmailAbilityToAbility = (
  ability: ExerciseAbilityForEmail
): ExerciseAbility => {
  return ability === 'ADVANCED+' ? 'LIFESTYLE' : ability;
};
