import { RecipePackValidation } from '../../types/adminPortalApiSchema';
import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';

export async function fetchRecipePack(
  id: string
): Promise<{
  data: { recipePackValidation: RecipePackValidation };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    recipePackValidation: RecipePackValidation;
    errors: [{ message: string }];
  }>(
    gql`
      query GetRecipePack($id: ID!) {
        recipePackValidation(id: $id) {
          id
          recipeCount
          sequence
          suitability
          recipes {
            id
            name
            ingredientsListsMatch
            secondaryIngredientsListsMatch
          }
          contentfulErrors {
            code
            details
            locations {
              column
              line
            }
            message
            path
            requestId
          }
        }
      }
    `,
    { id: id }
  );
}
