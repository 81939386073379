import React, { ReactElement } from 'react';
import {
  DataTable,
  DataTableHeader,
  TableExpandedRow,
  TableExpandHeader,
  TableExpandRow,
} from 'carbon-components-react';
import { RecipeValidation } from '../../types/adminPortalApiSchema';
import { capitalize } from '../../utils/utils';
import { BCStructuredList } from '../BCStructuredList/BCStructuredList';

const {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

export const HEADINGS: DataTableHeader<string>[] = [
  {
    header: 'name',
    key: 'name',
  },
  {
    header: 'status',
    key: 'status',
  },
  {
    header: 'contentful ID',
    key: 'id',
  },
];

function statusEmoji(status: boolean): string {
  return status === true ? '✅' : '🚫';
}

const RecipePackDataTable = ({
  recipes,
}: {
  recipes: RecipeValidation[];
}): ReactElement => {
  const rows = recipes.map((recipe) => ({
    /** Overall status of validation for the recipe */
    status:
      recipe.ingredientsListsMatch && recipe.secondaryIngredientsListsMatch,
    ...recipe,
  }));

  return (
    <div>
      <DataTable
        isSortable={true}
        key={'recipePack'}
        rows={rows}
        headers={HEADINGS}
        render={({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
        }): ReactElement => (
          <div data-testid="data-table">
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  {headers.map((header) => {
                    return (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                      >
                        {capitalize(header.header.toString())}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    {
                      // At the moment, Carbon is missing some types.
                      // Once fixed, we can update our types and remove this use of `@ts-ignore`
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          const cellType = cell.id.split(':')[1];
                          if (cellType === 'status') {
                            return (
                              <TableCell key={cell.id}>
                                {statusEmoji(cell.value)}
                              </TableCell>
                            );
                          } else if (cellType === 'id') {
                            return (
                              <TableCell key={cell.id}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://app.contentful.com/spaces/450abldm8aui/entries/${cell.value}`}
                                >
                                  {cell.value}
                                </a>
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          );
                        })}
                      </TableExpandRow>
                    }
                    <TableExpandedRow colSpan={headers.length + 1}>
                      <BCStructuredList
                        params={[
                          {
                            text: 'M/F ingredients lists match',
                            value: statusEmoji(
                              recipes[index].ingredientsListsMatch
                            ),
                          },
                          {
                            text: 'M/F secondary ingredients lists match',
                            value: statusEmoji(
                              recipes[index].secondaryIngredientsListsMatch
                            ),
                          },
                        ]}
                      />
                    </TableExpandedRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      />
    </div>
  );
};

export default RecipePackDataTable;
