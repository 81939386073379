import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledBlueButton } from '../../Button/Button';
import CheckmarkIcon from './circled_checkmark_icon.svg';

type Props = {
  headline: string;
  byline?: string; // if none is passed, no byline will be shown.
  continueButtonText: string;
  continueButtonCallback: () => void;
  continueToPath?: string | null; // if null is passed (or prop isn't passed), clicking the continueButton will not redirect the user away from the current page.
};

export const ContinueDialog = ({
  headline,
  byline,
  continueButtonText,
  continueButtonCallback,
  continueToPath,
}: Props): ReactElement | null => {
  return (
    <ContinueTakeover>
      <ContinueWrapper>
        <CheckmarkIconImg src={CheckmarkIcon} alt={headline}></CheckmarkIconImg>
        <Heading>{headline}</Heading>
        {byline && <p>{byline}</p>}
        <ContinueButton
          data-testid={'continue-button'}
          onClick={continueButtonCallback}
          href={continueToPath}
          kind="secondary"
          size="default"
          tabIndex={0}
          type="button"
        >
          {continueButtonText}
        </ContinueButton>
      </ContinueWrapper>
    </ContinueTakeover>
  );
};

const ContinueTakeover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
`;

const ContinueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 357px;
  width: 352px;
  position: relative;
`;

export const ContinueButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  width: 100%;
  height: 48px;
  margin: 20px 0px;
`;

const CheckmarkIconImg = styled.img`
  margin-top: 50px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  width: 200px;
`;
