import './ClientProfileSection.scss';
import { StructuredListWrapper } from 'carbon-components-react';
import React, { ReactElement, useState } from 'react';
import { capitalize } from 'lodash';
import spacetime from 'spacetime';

import { HorizontalRule, Title } from '../Theme/Theme';
import styled from 'styled-components';
import { UserIcon } from '../Icons/User';
import { OpenNow } from '../Icons/OpenNow';
import {
  ClientDetail,
  ClientDataInput,
  ExerciseAbility,
  Scalars,
} from '../../types/adminPortalApiSchema';
import { StyledBlueButton } from '../Button/Button';
import { UpdateUserArgs } from '../../pages/Client/Client';
import { ClientSaveSection } from './ClientSaveSection';
import { EditCycle } from '../Dialogs/EditCycle/EditCycle';
import { EditName } from '../Dialogs/EditName/EditName';

import {
  dateInDaysFromNow,
  dayDifferenceBetweenDates,
  EditDaysToCheckIn,
} from '../Dialogs/EditDaysToCheckIn/EditDaysToCheckIn';
import { CYCLE_LENGTH } from '../../constants';
import { orderCyclesByCreatedAtDate } from '../../utils/utils';
import { ClientProfileList, TopPaddedButton } from './ClientProfileList';
import { Edit16 } from '@carbon/icons-react';
import { WithBlueBullet } from '../Icons/BlueBullet';
import { EditInitialWeight } from '../Dialogs/EditInitialWeight/EditInitialWeight';

type Props = {
  user: ClientDetail;
  hasBeenEdited: boolean;
  updateUser: (args: UpdateUserArgs) => void;
  updatePendingUserChanges: (args: Partial<ClientDataInput>) => void;
  pendingUserChanges: ClientDataInput;
  isValid: boolean;
};

export const ClientProfileSection = ({
  user,
  hasBeenEdited,
  updateUser,
  updatePendingUserChanges,
  pendingUserChanges,
  isValid,
}: Props): ReactElement => {
  const latestCycle =
    (user &&
      user.cycles &&
      orderCyclesByCreatedAtDate(
        user.cycles.filter(({ cycleNumber }) => cycleNumber !== 0)
      )[0]) ||
    null;

  const calculateCheckInDateForCycleStart = (d: Date) =>
    new Date(new Date(d).setDate(new Date(d).getDate() + CYCLE_LENGTH));

  const latestCycleCheckInDate = calculateCheckInDateForCycleStart(
    new Date(latestCycle?.cycleCreatedAt)
  );

  const checkInCountdown = dayDifferenceBetweenDates(
    new Date(),
    latestCycleCheckInDate
  );

  const editCycleDefaultStateModal: {
    show: boolean;
    cycleId: string | null;
    ability: ExerciseAbility | null;
    cycleNumber: number | null;
  } = { show: false, cycleId: null, ability: null, cycleNumber: null };

  const editNameDefaultStateModal: {
    show: boolean;
    name: string | null;
  } = { show: false, name: null };

  const editDaysToCheckInDefaultStateModal: {
    show: boolean;
    daysToCheckIn: number | null;
  } = { show: false, daysToCheckIn: null };

  const editInitialWeightDefaultStateModal: {
    show: boolean;
    initialWeight: Scalars['Float'] | null;
  } = { show: false, initialWeight: null };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showEditCycle, setShowEditCycle] = useState(
    editCycleDefaultStateModal
  );
  const [showEditName, setShowEditName] = useState(editNameDefaultStateModal);
  const [showDaysToCheckIn, setShowDaysToCheckIn] = useState(
    editDaysToCheckInDefaultStateModal
  );

  const [showInitialWeight, setShowInitialWeight] = useState(
    editInitialWeightDefaultStateModal
  );

  const editCycleModal = ({
    cycleId,
    ability,
    cycleNumber,
  }: {
    cycleId: string;
    ability: ExerciseAbility;
    cycleNumber: number;
  }): void => {
    setShowEditCycle({ show: true, cycleId, ability, cycleNumber });
  };

  const editDaysToCheckInModal = ({
    daysToCheckIn,
  }: {
    daysToCheckIn: number;
  }): void => {
    setShowDaysToCheckIn({ show: true, daysToCheckIn });
  };

  const editInitialWeightModal = ({
    initialWeight,
  }: {
    initialWeight: Scalars['Float'];
  }): void => {
    setShowInitialWeight({ show: true, initialWeight });
  };

  const editNameModal = ({ name }: { name: string }): void => {
    setShowEditName({ show: true, name });
  };

  const weightInKg = user.initialWeight?.measurement
    ? user.initialWeight?.measurement / 1000
    : 0;
  const dataToDisplay = {
    initialWeight: pendingUserChanges.initialWeight || weightInKg,
    name: pendingUserChanges.name || user.name,
    ability:
      pendingUserChanges.currentAbilityAndCycle?.ability ||
      latestCycle?.ability,
    cycleNumber:
      pendingUserChanges.currentAbilityAndCycle?.cycleNumber ||
      latestCycle?.cycleNumber,
    cycleId: latestCycle?.cycleId,
    checkInCountdown:
      typeof pendingUserChanges.daysToCheckIn === 'number'
        ? pendingUserChanges.daysToCheckIn
        : checkInCountdown,
    checkInDate: dateInDaysFromNow(
      typeof pendingUserChanges.daysToCheckIn === 'number'
        ? pendingUserChanges.daysToCheckIn
        : checkInCountdown
    ),
  };

  const openNowCopy =
    dataToDisplay.checkInCountdown === 0
      ? 'Today'
      : `${-dataToDisplay.checkInCountdown} day${
          dataToDisplay.checkInCountdown === -1 ? '' : 's'
        } ago`;

  return (
    <>
      {showEditCycle?.cycleId &&
        showEditCycle?.ability &&
        showEditCycle?.cycleNumber && (
          <EditCycle
            headline={`Edit plan`}
            data={{
              cycleId: showEditCycle.cycleId,
              ability: showEditCycle.ability,
              cycleNumber: showEditCycle.cycleNumber,
            }}
            loading={isLoading}
            dismissButtonText="Cancel"
            confirmButtonText="OK"
            confirmButtonColor="blue"
            updateCycleCallback={async ({
              ensureCycleContentIsAvailable,
              ...dataToUpdate
            }): Promise<void> => {
              setLoading(true);
              // Check there is some workout content for the ability / cycle number
              const cycleIsValid = await ensureCycleContentIsAvailable();
              setLoading(false);

              if (cycleIsValid) {
                const hasChanged =
                  latestCycle.ability !== dataToUpdate.ability ||
                  latestCycle.cycleNumber !== dataToUpdate.cycleNumber;
                updatePendingUserChanges({
                  currentAbilityAndCycle: hasChanged ? dataToUpdate : null,
                });
                setShowEditCycle(editCycleDefaultStateModal);
              }
            }}
            dismissButtonCallback={(): void => {
              setShowEditCycle(editCycleDefaultStateModal);
            }}
          />
        )}
      {typeof showDaysToCheckIn?.daysToCheckIn === 'number' && (
        <EditDaysToCheckIn
          headline={`Edit next check-in`}
          data={{
            daysToCheckIn: dataToDisplay.checkInCountdown,
          }}
          loading={isLoading}
          dismissButtonText="Cancel"
          confirmButtonText="OK"
          confirmButtonColor="blue"
          updateCheckInCallback={async ({ daysToCheckIn }): Promise<void> => {
            const hasChanged = checkInCountdown !== daysToCheckIn;
            updatePendingUserChanges({
              daysToCheckIn: hasChanged ? daysToCheckIn : null,
            });
            setShowDaysToCheckIn(editDaysToCheckInDefaultStateModal);
          }}
          dismissButtonCallback={(): void => {
            setShowDaysToCheckIn(editDaysToCheckInDefaultStateModal);
          }}
        />
      )}

      {typeof showInitialWeight?.initialWeight === 'number' && (
        <EditInitialWeight
          headline={`Edit initial weight.`}
          data={{
            initialWeight: dataToDisplay.initialWeight,
          }}
          loading={isLoading}
          dismissButtonText="Cancel"
          confirmButtonText="OK"
          confirmButtonColor="blue"
          updateInitialWeightCallback={async ({
            initialWeight,
          }): Promise<void> => {
            const hasChanged = weightInKg !== initialWeight;
            updatePendingUserChanges({
              initialWeight: hasChanged ? initialWeight : null,
            });
            setShowInitialWeight(editInitialWeightDefaultStateModal);
          }}
          dismissButtonCallback={(): void => {
            setShowInitialWeight(editInitialWeightDefaultStateModal);
          }}
        />
      )}
      {typeof showEditName?.name === 'string' && (
        <EditName
          headline={`Edit the user's first name`}
          data={{
            name: dataToDisplay.name,
          }}
          loading={isLoading}
          dismissButtonText="Cancel"
          confirmButtonText="OK"
          confirmButtonColor="blue"
          updateNameCallback={async ({ name }): Promise<void> => {
            const hasChanged = name !== user.name;
            updatePendingUserChanges({
              name: hasChanged ? name : null,
            });
            setShowEditName(editNameDefaultStateModal);
          }}
          dismissButtonCallback={(): void => {
            setShowEditName(editNameDefaultStateModal);
          }}
        />
      )}
      <Wrapper>
        <StyledUserIconWrapper>
          <UserIcon />
        </StyledUserIconWrapper>
        <HeadingWrapper>
          <SubHeadingWrapper>
            <Title>
              {pendingUserChanges.name != null ? (
                <WithBlueBullet copy={dataToDisplay.name} leftWidth={70} />
              ) : (
                dataToDisplay.name
              )}
            </Title>
            {dataToDisplay.ability &&
              dataToDisplay.cycleId &&
              dataToDisplay.cycleNumber && (
                <TopPaddedButton
                  kind="ghost"
                  data-testid="edit-name-icon"
                  iconDescription="icon"
                  renderIcon={Edit16}
                  onClick={(): void => {
                    editNameModal({ name: dataToDisplay.name });
                  }}
                />
              )}
          </SubHeadingWrapper>
          <UserInfo>
            <Light>{`Email:`}</Light>
            <span>{user.email}</span>
          </UserInfo>
          <UserInfo>
            <Light>{`User ID:`}</Light>
            <span>{user.id}</span>
            <StyledExternalLink
              href={`https://app.revenuecat.com/customers/f300e683/${user.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Revenuecat profile
            </StyledExternalLink>
            <StyledExternalLink
              href={`https://app.iterable.com/users/profiles/${user.email}/fields`}
              target="_blank"
              rel="noreferrer"
            >
              Iterable profile
            </StyledExternalLink>
          </UserInfo>
          <ClientSaveSection
            user={user}
            hasBeenEdited={hasBeenEdited}
            updateUser={updateUser}
            isValid={isValid}
            pendingUserChanges={pendingUserChanges}
            updatePendingUserChanges={updatePendingUserChanges}
            genericError="Failed to client profile"
          />
        </HeadingWrapper>

        <ProfileWrapper>
          <ProfileContentWrapper>
            <LeftStructuredListWrapper
              style={{ width: '100%' }}
              ariaLabel="Structured list"
            >
              <ClientProfileList
                header={'Client Details'}
                editCycleModalCallback={editCycleModal}
                editDaysToCheckInCallback={editDaysToCheckInModal}
                editInitialWeightCallback={editInitialWeightModal}
                listItems={[
                  {
                    hide: !user.sex,
                    text: 'Sex',
                    value: capitalize(user.sex as string),
                  },
                  {
                    hide: !user.dob,
                    text: 'DOB',
                    value: spacetime(new Date(user.dob))
                      .format('{date-pad}/{iso-month}/{year}')
                      .toString(),
                  },
                  {
                    hide: !latestCycle,
                    editable: true,
                    text: 'Check-in day',
                    value:
                      dataToDisplay.checkInCountdown < 1 ? (
                        <OpenNow copy={openNowCopy} />
                      ) : (
                        `${dataToDisplay.checkInCountdown} day${
                          dataToDisplay.checkInCountdown === 1 ? '' : 's'
                        }  |  ${spacetime(dataToDisplay.checkInDate)
                          .format('{date-pad}/{iso-month}/{year}')
                          .toString()}`
                      ),
                    daysToCheckIn: checkInCountdown,
                    isDirty: pendingUserChanges.daysToCheckIn != null,
                  },
                  {
                    hide: !user.createdAt,
                    text: 'Account creation',
                    value: spacetime(user.createdAt)
                      .format('{date-pad}/{iso-month}/{year}')
                      .toString(),
                  },
                  {
                    hide: !latestCycle,
                    editable: true,
                    text: 'Initial Weight (kg)',
                    value: dataToDisplay.initialWeight,
                    initialWeight: user.initialWeight?.measurement,
                    isDirty: pendingUserChanges.initialWeight != null,
                  },
                  {
                    hide: !user?.height?.measurement,
                    text: 'Height (cm)',
                    value: user?.height?.measurement
                      ? user?.height?.measurement / 10
                      : 0,
                  },
                ]}
              ></ClientProfileList>
            </LeftStructuredListWrapper>
          </ProfileContentWrapper>
        </ProfileWrapper>
      </Wrapper>
      <HorizontalRule />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ProfileContentWrapper = styled.div`
  display: flex;
`;

const LeftStructuredListWrapper = styled((props) => (
  <StructuredListWrapper {...props} />
))``;

const UserInfo = styled.p`
  margin-bottom: 10px;
  font-size: 1.1rem;
`;
const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledUserIconWrapper = styled.div`
  margin-right: 10px;
  margin-top: 2px;
`;

const Light = styled.span`
  margin-right: 5px;
  color: ${(props): string => props.theme.bodyCopyLight};
`;

const StructuredListTitle = styled.span`
  font-weight: bold;
`;

export const StyledSaveChangesButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin-top: 16px;
  max-width: 178px;
`;

const StyledExternalLink = styled.a`
  display: block;
  margin-top: 16px;
`;
