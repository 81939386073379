import React, { ReactElement } from 'react';
import { RecipeValidation } from '../../types/adminPortalApiSchema';
import RecipePackDataTable from './RecipePackDataTable';

export function sortRecipesByName(
  recipe1: RecipeValidation,
  recipe2: RecipeValidation
): number {
  return recipe1.name < recipe2.name ? -1 : 1;
}

export const RecipePackTable = ({
  recipes,
}: {
  recipes: RecipeValidation[];
}): ReactElement => {
  return <RecipePackDataTable recipes={[...recipes].sort(sortRecipesByName)} />;
};
