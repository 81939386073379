import styled from 'styled-components';
import React from 'react';

export function Footer() {
  return <StyledFooter />;
}

const StyledFooter = styled.footer`
  height: 40px;
  background: ${(props) => props.theme.inspireCobalt};
`;
