import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { Heading2 } from '../Theme/Theme';
import { StyledBlueButton } from '../Button/Button';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { invalidateCache } from './invalidateCacheQuery';

export const CacheInvalidation = (): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  return (
    <Wrapper>
      <div>
        <Heading2>Invalidate cache now</Heading2>
        <p>
          Please bear in mind invalidating the production contentful cache may
          result in a small number of errors for users of both the live
          production app and the internal app for a few seconds. Try to avoid
          invalidating the cache at peak times to limit the number of users
          affected.
        </p>
      </div>
      <StyledInvalidateCacheButton
        data-testid={'invalidate-cache-button'}
        onClick={(): void => {
          setShowConfirmationDialog(true);
        }}
        kind="secondary"
        size="default"
        tabIndex={0}
        type="button"
      >
        Invalidate Cache
      </StyledInvalidateCacheButton>
      {showConfirmationDialog && (
        <ConfirmationDialog
          headline={`Are you sure you want to invalidate the cache?`}
          byline="After invalidating the cache, there may be a small number of errors for a short while."
          dismissButtonText="Cancel"
          confirmButtonText="Invalidate Cache"
          confirmButtonColor="red"
          confirmButtonCallback={(): Promise<void> => {
            return invalidateCache()
              .then((res) => {
                const success = res?.data?.invalidateCache;
                if (success?.id && success?.timestamp) {
                  setShowConfirmationDialog(false);
                  setShowContinueDialog(true);
                } else {
                  throw new Error('Received a response, but was not success.');
                }
              })
              .catch((err) => {
                setShowConfirmationDialog(false);
                alert(
                  `Invalidating the cache failed, please contact an administrator to help resolve this. ${err.toString()}`
                );
              });
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
          }}
          warning={true}
        ></ConfirmationDialog>
      )}
      {showContinueDialog && (
        <ContinueDialog
          headline={`Contentful cache invalidated`}
          continueToPath="/content/"
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
          }}
        ></ContinueDialog>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  border: 1px solid grey;
  margin-top: 70px;
`;

export const StyledInvalidateCacheButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  width: 178px;
  height: 48px;
`;
