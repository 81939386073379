import React from 'react';
import spacetime from 'spacetime';
import styled from 'styled-components';
import { TrackedWorkout } from '../../types/adminPortalApiSchema';
import WorkoutWatchedStatus from './WorkoutWatchedStatus';

type Props = {
  workouts: TrackedWorkout[];
};

const TrackedWorkoutTable: React.FC<Props> = ({ workouts }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableHeader>Date time</StyledTableHeader>
          <StyledTableHeader>Workout ID</StyledTableHeader>
          <StyledTableHeader>Trainer</StyledTableHeader>
          <StyledTableHeader>Watched/Duration (seconds)</StyledTableHeader>
        </tr>
      </thead>
      <tbody>
        {workouts.map((trackedWorkout) => {
          const percentageWatched = Math.round(
            (trackedWorkout.secondsWatched / trackedWorkout.workoutDuration) *
              100
          );
          return (
            <tr key={`${trackedWorkout.sessionId}_${trackedWorkout.workoutId}`}>
              <StyledTableCell>
                {spacetime(trackedWorkout.timestamp).format(
                  '{date-pad}/{iso-month}/{year} {hour-pad}:{minute-pad}'
                )}
              </StyledTableCell>
              <StyledTableCell>{trackedWorkout.workoutId}</StyledTableCell>
              <StyledTableCell>{trackedWorkout.trainer}</StyledTableCell>
              <StyledTableCell
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {trackedWorkout.secondsWatched}/{trackedWorkout.workoutDuration}{' '}
                ({percentageWatched}%){' '}
                <WorkoutWatchedStatus
                  isWatched={trackedWorkout.workoutIsWatched}
                />
              </StyledTableCell>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  margin-top: 16px;
`;

const StyledTableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 8px;
`;

const StyledTableCell = styled.td`
  padding: 8px;
`;
export default TrackedWorkoutTable;
