import React, { ReactElement, useState, useEffect } from 'react';
import './styles.scss';
import {
  ClientDataInput,
  ClientDetail,
} from '../../types/adminPortalApiSchema';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { LoadingDialog } from '../Dialogs/Loading/Loading';

import { updateClientData } from './updateClientData';
import { ErrorMessage } from '../Theme/Theme';
import { UpdateUserArgs } from '../../pages/Client/Client';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
type Props = {
  user: ClientDetail;
  hasBeenEdited: boolean;
  updateUser: (args: UpdateUserArgs) => void;
  pendingUserChanges: ClientDataInput;
};

export const ClientSaveModal = ({
  hasBeenEdited,
  updateUser,
  pendingUserChanges,
}: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [redirect, setRedirect] = useState('/clients');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const history = useHistory();

  const handleSaveChanges = (): void => {
    if (hasBeenEdited) {
      setLoading(true);
      updateClientData(pendingUserChanges)
        .then((res) => {
          setLoading(false);
          setShowContinueDialog(true);
          if (res?.data?.clientDataInput) {
            updateUser({
              update: res.data.clientDataInput,
            });
          }
        })
        .catch((err) => {
          console.log({ err });
          setLoading(false);
          setShowContinueDialog(false);
          setHasErrored(true);
        });
    }
  };

  useEffect(() => {
    const unblock = history.block(function (targetLocation: Location) {
      if (hasBeenEdited) {
        setRedirect(targetLocation.pathname);
        setShowConfirmationDialog(true);
        return false;
      }
    });
    return () => {
      unblock();
    };
  }, [hasBeenEdited, history, showContinueDialog, showConfirmationDialog]);

  return (
    <div>
      {showConfirmationDialog && !isLoading && (
        <ConfirmationDialog
          headline={`Do you want to save those changes`}
          byline="It looks like you've made some changes, would you like to save these before you move on?"
          dismissButtonText="Don't Save"
          confirmButtonText="Save"
          confirmButtonColor="blue"
          confirmButtonCallback={async (): Promise<void> => {
            setShowConfirmationDialog(false);
            handleSaveChanges();
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
          }}
          dismissToPath={`${redirect}`}
          warning={false}
        />
      )}
      {hasErrored && <ErrorMessage>Something went wrong</ErrorMessage>}
      {isLoading && <LoadingDialog heading={'Saving your work'} />}
      {showContinueDialog && (
        <ContinueDialog
          headline={'Your changes have been saved'}
          continueToPath={`${redirect}`}
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
          }}
        />
      )}
    </div>
  );
};
