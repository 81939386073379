import React, { ReactElement } from 'react';
import { Theme } from './components/Theme/Theme';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import styled from 'styled-components';
import { Reset } from './CSS/Reset';
import { GlobalStyles } from './CSS/Global';
import { BreadcrumbNav } from './components/Breadcrumb/Breadcrumb';

type Props = {
  isLoggedIn: boolean;
  children: ReactElement[];
};
export function Template(props: Props): ReactElement {
  const { children, isLoggedIn } = props;
  return (
    <Theme>
      <Reset />
      <GlobalStyles />
      <StyledWrapper>
        <TopBar />
        <Header isLoggedIn={isLoggedIn} />
        <MainWrapper>
          <BreadcrumbNav />
          <Content>{children}</Content>
        </MainWrapper>
        <Footer />
      </StyledWrapper>
    </Theme>
  );
}

const Content = styled.div`
  flex: 1;
  padding: ${(props): string => props.theme.contentPadding};
`;

const MainWrapper = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TopBar = styled.div`
  height: 16px;
  background: ${(props) => props.theme.inspireCobalt};
`;

const StyledWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
