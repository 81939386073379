import React, { ReactElement } from 'react';
import { DataTable, DataTableHeader } from 'carbon-components-react';
import { RecipePackSummary } from '../../types/adminPortalApiSchema';
import { capitalize, isLastItemInArray } from '../../utils/utils';
import { StyledButton } from '../Button/Button';

const {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

export const HEADINGS: DataTableHeader<string>[] = [
  {
    header: 'suitability',
    key: 'suitability',
  },
  {
    header: 'sequence',
    key: 'sequence',
  },
  {
    header: 'recipe pack name',
    key: 'recipePackName',
  },
  {
    header: 'recipe count',
    key: 'recipeCount',
  },
  {
    header: 'snack count',
    key: 'snackCount',
  },
  {
    header: 'refuel count',
    key: 'refuelCount',
  },
  {
    header: 'general count',
    key: 'generalCount',
  },
  {
    header: 'contentful ID',
    key: 'id',
  },
  {
    header: 'details',
    key: 'details',
  },
];

const RecipePacksDataTable = ({
  rows,
}: {
  rows: RecipePackSummary[];
}): ReactElement => {
  return (
    <div>
      <DataTable
        isSortable={true}
        key={'recipePacks'}
        rows={rows}
        headers={HEADINGS}
        render={({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
        }): ReactElement => (
          <div data-testid="data-table">
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    return (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        key={header.key}
                      >
                        {capitalize(header.header.toString())}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })} key={row.id}>
                    {row.cells.map((cell, i, arr) => {
                      const cellType = cell.id.split(':')[1];

                      if (isLastItemInArray(arr, i)) {
                        return (
                          <TableCell key={row.id + 'details'}>
                            <StyledButton
                              href={`/content/recipepacks/${row.id}`}
                              kind="secondary"
                              size="default"
                              tabIndex={0}
                              type="button"
                            >
                              View Details
                            </StyledButton>
                          </TableCell>
                        );
                      } else if (cellType === 'id') {
                        return (
                          <TableCell key={cell.id}>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                'https://app.contentful.com/spaces/450abldm8aui/entries/' +
                                cell.value
                              }
                            >
                              {cell.value}
                            </a>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      />
    </div>
  );
};

export default RecipePacksDataTable;
