import spacetime from 'spacetime';
import './ClientStatsSection.scss';
import { EditStats } from '../Dialogs/EditStats/EditStats';
import { UpdateUserArgs } from '../../pages/Client/Client';

import {
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  StructuredListWrapper,
  Select,
  SelectItem,
  Button,
} from 'carbon-components-react';
import React, {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  ClientDetail,
  UserCycleStat,
  MeasurementType,
  UserBodyStat,
} from '../../types/adminPortalApiSchema';
import { Heading2, HorizontalRule } from '../Theme/Theme';
import styled from 'styled-components';
import {
  getMeasurementUnit,
  getMeasurementTitle,
  convertToUnit,
  sortBodyStats,
} from './ClientStatsSectionHelpers';

import { Edit16 } from '@carbon/icons-react';
import { updateClientStats } from './updateClientStats';

type Props = {
  user: ClientDetail;
  updateUser: (args: UpdateUserArgs) => void;
};

export const BodyStatsList = ({
  user,
  updateUser,
  measurements,
}: { measurements: UserCycleStat } & Props): ReactElement => {
  const defaultingStartingStats =
    measurements.cycleNumber === 0 && !measurements?.completed;
  const defaultStateModal: {
    show: boolean;
    bodyStat: UserBodyStat | null;
  } = { show: false, bodyStat: null };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showEditStats, setShowEditStats] = useState(defaultStateModal);
  const editStatField = (elem: UserBodyStat): void => {
    setShowEditStats({ show: true, bodyStat: elem });
  };

  const bodyStats: UserBodyStat[] = measurements?.bodyStats || [];

  // todo create a stats with weight when tailoring
  return (
    <>
      {showEditStats?.bodyStat && (
        <EditStats
          headline={`Edit Stats`}
          data={{
            bodyStat: showEditStats.bodyStat,
          }}
          loading={isLoading}
          dismissButtonText="Cancel"
          confirmButtonText="Save"
          confirmButtonColor="blue"
          updateUserStatsCallback={async (
            bodyStatToUpdate: UserBodyStat
          ): Promise<void> => {
            setLoading(true);
            const unitConversion =
              bodyStatToUpdate.measurementType === 'WEIGHT'
                ? 'toGrams'
                : 'toMm';
            const newBodyStats = {
              [bodyStatToUpdate.measurementType]: convertToUnit(
                bodyStatToUpdate.measurement,
                unitConversion
              ),
            };
            const payload = {
              cycleId: measurements.cycleId,
              userId: user.id,
              bodyStats: newBodyStats,
            };
            const { data } = await updateClientStats(payload);
            if (!data) {
              setLoading(false);
              alert(
                'Unable to update user stat, please contact an administrator to help resolve this.'
              );
            } else {
              const {
                updateLatestUserStats: {
                  cycleId,
                  macros,
                  bodyStats,
                  initialWeight: initialWeightMeasurement,
                },
              } = data;
              console.log({ data });
              if (!initialWeightMeasurement?.measurement) {
                setLoading(false);
                alert('Data returned was incomplete.');
              }
              const bodyStatsTypes = bodyStats.map(
                (elem) => elem.measurementType
              );
              // update the user with new stats
              const newStats = user.cycles.map((elem) => {
                if (elem.cycleId === cycleId) {
                  const step = elem.bodyStats
                    ?.filter((bodyStat) => {
                      return !bodyStatsTypes.includes(bodyStat.measurementType);
                    })
                    .concat(bodyStats);
                  return {
                    ...elem,
                    bodyStats: step,
                  };
                }
                return elem;
              });
              updateUser({
                update: {
                  ...(macros && { macros }),
                  cycles: newStats,
                  initialWeight: initialWeightMeasurement,
                },
              });
              setLoading(false);
            }
            setShowEditStats(defaultStateModal);
          }}
          dismissButtonCallback={(): void => {
            setShowEditStats(defaultStateModal);
          }}
        />
      )}
      <StructuredListBody>
        {bodyStats.length === 0 ? (
          <StructuredListRow>
            <StructuredListCell>No stats submitted yet</StructuredListCell>
          </StructuredListRow>
        ) : (
          bodyStats.sort(sortBodyStats).map((bodyStat, index) => {
            const unit = getMeasurementUnit(bodyStat.measurementType);
            const convertedUnit = convertToUnit(bodyStat.measurement, unit);
            const isEditable = true;
            return (
              <StructuredListRow key={index}>
                <StatCell>
                  <StatSpan>
                    {getMeasurementTitle(
                      bodyStat.measurementType as MeasurementType
                    )}{' '}
                    ({unit}) {convertedUnit || `--`}
                  </StatSpan>
                  {isEditable && (
                    <Button
                      kind="ghost"
                      data-testid="edit-stat-icon"
                      iconDescription="icon"
                      renderIcon={Edit16}
                      onClick={(): void =>
                        editStatField({
                          ...bodyStat,
                          measurement: convertedUnit,
                        })
                      }
                    />
                  )}
                </StatCell>
              </StructuredListRow>
            );
          })
        )}
      </StructuredListBody>
    </>
  );
};

export const ClientStatsSection = ({
  user,
  updateUser,
}: Props): ReactElement => {
  const stats = user.cycles || [];

  const [leftSelectedIndex, setLeftSelectedIndex] = useState(0);
  const [rightSelectedIndex, setRightSelectedIndex] = useState(0);

  const stringifiedStats = JSON.stringify(stats);
  const [
    leftStructuredListMeasurements,
    setLeftStructuredListMeasurements,
  ] = useState<UserCycleStat>(stats[leftSelectedIndex] || []);
  const [
    rightStructuredListMeasurements,
    setRightStructuredListMeasurements,
  ] = useState<UserCycleStat>(stats[rightSelectedIndex] || []);

  useEffect(() => {
    const comingStats = JSON.parse(stringifiedStats);
    setLeftSelectedIndex(leftSelectedIndex);
    setLeftStructuredListMeasurements(comingStats[leftSelectedIndex] || []);
    setRightSelectedIndex(rightSelectedIndex);
    setRightStructuredListMeasurements(comingStats[rightSelectedIndex] || []);
  }, [stringifiedStats]);

  const renderSelectOptions = (): ReactElement[] =>
    stats.map((stat, index) => {
      const formattedDate = stat.cycleUpdatedAt
        ? spacetime(stat.cycleUpdatedAt)
            .format('{date-pad}/{iso-month}/{year}')
            .toString()
        : 'DATE ERROR';
      const text =
        stat.cycleNumber === 0
          ? `START - ${formattedDate}`
          : `${(stat.ability as string).substring(0, 3)} - CYC ${
              stat.cycleNumber
            } - ${formattedDate}`;
      return <SelectItem key={index} text={text} value={index} />;
    });

  const changeIndex = (
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>,
    setStructuredListMeasurements: Dispatch<SetStateAction<UserCycleStat>>
  ) => {
    return (event: ChangeEvent<HTMLSelectElement>): void => {
      setSelectedIndex(parseFloat(event.target.value));
      setStructuredListMeasurements(stats[parseInt(event.target.value, 10)]);
    };
  };
  return (
    <>
      <Wrapper>
        <Header>
          <Heading2>Client progress</Heading2>
          <p>Compare the clients progress across cycles and plans</p>
        </Header>
        <StatsWrapper>
          {!user.cycles?.length ? (
            <EmptyStatsMessage>User has no stats</EmptyStatsMessage>
          ) : (
            <>
              <LeftStructuredListWrapper ariaLabel="Structured list">
                <Select
                  id="select-1"
                  invalidText="A valid value is required"
                  labelText="Select"
                  hideLabel={true}
                  onChange={changeIndex(
                    setLeftSelectedIndex,
                    setLeftStructuredListMeasurements
                  )}
                  defaultValue={leftSelectedIndex}
                >
                  {renderSelectOptions()}
                </Select>
                <BodyStatsList
                  updateUser={updateUser}
                  user={user}
                  measurements={leftStructuredListMeasurements}
                />
              </LeftStructuredListWrapper>
              {stats.length > 1 && (
                <RightStructuredListWrapper ariaLabel="Structured list">
                  <Select
                    id="select-2"
                    invalidText="A valid value is required"
                    labelText="Select"
                    hideLabel={true}
                    onChange={changeIndex(
                      setRightSelectedIndex,
                      setRightStructuredListMeasurements
                    )}
                    defaultValue={rightSelectedIndex}
                  >
                    {renderSelectOptions()}
                  </Select>
                  <BodyStatsList
                    updateUser={updateUser}
                    user={user}
                    measurements={rightStructuredListMeasurements}
                  />
                </RightStructuredListWrapper>
              )}
            </>
          )}
        </StatsWrapper>
      </Wrapper>
      <HorizontalRule />
    </>
  );
};

const Header = styled.div`
  width: 50%;
`;

const StatsWrapper = styled.div`
  display: flex;
  width: 50%;
`;

const EmptyStatsMessage = styled.p`
  margin-bottom: 100px;
`;

const StatCell = styled(StructuredListCell)`
  display: flex;
  text-align: left;
  align-items: center;
  height: 50px;
`;

const StatSpan = styled.span`
  flex: 1;
`;

const LeftStructuredListWrapper = styled((props) => (
  <StructuredListWrapper {...props} />
))`
  width: 224px;
  margin-right: 80px;
`;

const RightStructuredListWrapper = styled((props) => (
  <StructuredListWrapper {...props} />
))`
  width: 224px;
  margin-right: 250px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
`;
