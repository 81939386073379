import React, { ReactElement } from 'react';
import { RecipePacksTable } from '../../components/RecipePacksTable/RecipePacksTable';
import { Heading } from '../../components/Theme/Theme';

export function ContentRecipePacks(): ReactElement {
  return (
    <>
      <Heading>Recipe Packs</Heading>

      <div>A table of recipe packs available.</div>

      <RecipePacksTable />
    </>
  );
}
