import styled from 'styled-components';
import Button from 'carbon-components-react/lib/components/Button';
import React from 'react';
import { ThemeColors } from '../Theme/colors';

type TextStyleConfig = {
  background: string;
  text: string;
  border: string;
  hover: {
    background: string;
    text: string;
  };
};

type ButtonColorConfigArgs = {
  theme: ThemeColors;
  color: 'blue' | 'red';
};

const buttonColorConfig = ({
  theme,
  color,
}: ButtonColorConfigArgs): TextStyleConfig => {
  const defaults = {
    background: 'transparent',
    text: theme.inspireCobalt,
    border: theme.inspireCobalt,
    hover: {
      background: theme.inspireCobalt,
      text: theme.white,
    },
  };

  const config = {
    blue: {
      ...defaults,
      background: theme.inspireCobalt,
      text: theme.white,
    },
    red: {
      ...defaults,
      background: theme.warningRed,
      border: theme.white,
      text: theme.white,
      hover: {
        ...defaults.hover,
        background: theme.warningRed,
      },
    },
  };

  return config[color] || defaults;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledButton = styled(({ color, ...rest }) => (
  <Button {...rest} />
))`
  text-align: center;
  margin: 10px 10px 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px;
  background-color: ${(props): string =>
    buttonColorConfig(props)['background']};
  border: 1px solid ${(props): string => buttonColorConfig(props)['border']};
  color: ${(props): string => buttonColorConfig(props)['text']};
  &:hover {
    background: ${(props): string =>
      buttonColorConfig(props)['hover']['background']};
    color: ${(props): string => buttonColorConfig(props)['hover']['text']};
  }
`;

export const StyledBlueButton = styled((props) => (
  <StyledButton color={'blue'} {...props} />
))``;

export const StyledRedButton = styled((props) => (
  <StyledButton color={'red'} {...props} />
))``;
