import React, { useState } from 'react';
import styled from 'styled-components';
import { ClientDetail } from '../../types/adminPortalApiSchema';
import BadgesTable from '../BadgesTable/BadgesTable';
import { StyledBlueButton } from '../Button/Button';
import { Heading2 } from '../Theme/Theme';
import TrackedWorkoutTable from '../TrackedWorkoutTable/TrackedWorkoutTable';

type Props = {
  user: ClientDetail;
};

const ClientWorkoutsSection: React.FC<Props> = ({ user }) => {
  const trackedWorkouts = user.trackedWorkouts || [];
  const lastThreeTrackedWorkouts = trackedWorkouts.slice(0, 3);

  const badges = user.badges || [];
  const lastThreeBadges = badges.slice(0, 3);

  const [workoutsToShow, setWorkoutsToShow] = useState(
    lastThreeTrackedWorkouts
  );

  const [badgesToShow, setBadgesToShow] = useState(lastThreeBadges);

  const workoutsWatched = trackedWorkouts.filter(
    (trackedWorkout) => trackedWorkout.workoutIsWatched
  ).length;

  const showingLastThreeWorkouts = workoutsToShow.length === 3;
  const showingLastThreeBadges = badgesToShow.length === 3;

  const toggleShowAllWorkouts = () => {
    if (showingLastThreeWorkouts) {
      setWorkoutsToShow(trackedWorkouts);
    } else {
      setWorkoutsToShow(lastThreeTrackedWorkouts);
    }
  };

  const toggleShowAllBadges = () => {
    if (showingLastThreeBadges) {
      setBadgesToShow(badges);
    } else {
      setBadgesToShow(lastThreeBadges);
    }
  };

  return (
    <ClientWorkoutsWrapper>
      <div>
        <Heading2>Tracked Workouts</Heading2>
        <StyledWorkoutMetricSummary>
          <StyledWorkoutMetricNumber>
            {workoutsWatched}
          </StyledWorkoutMetricNumber>{' '}
          workouts watched /{' '}
          <StyledWorkoutMetricNumber>
            {trackedWorkouts.length}
          </StyledWorkoutMetricNumber>{' '}
          workouts tracked
        </StyledWorkoutMetricSummary>
        <p>
          {showingLastThreeWorkouts
            ? 'Last 3 workouts tracked'
            : 'All tracked workouts'}
        </p>
        <TrackedWorkoutTable workouts={workoutsToShow} />
        <StyledBlueButton
          data-testid={'view-all-workouts-button'}
          onClick={toggleShowAllWorkouts}
        >
          {showingLastThreeWorkouts ? 'View All' : 'Collapse'}
        </StyledBlueButton>
      </div>
      <BadgesWrapper>
        <Heading2>Badges</Heading2>
        <StyledWorkoutMetricSummary>
          <StyledWorkoutMetricNumber>{badges.length}</StyledWorkoutMetricNumber>{' '}
          Badges awarded
        </StyledWorkoutMetricSummary>
        <p>
          {showingLastThreeBadges
            ? 'Last 3 badges awarded'
            : 'All badges awarded'}
        </p>
        <BadgesTable badges={badgesToShow} />
        <StyledBlueButton
          data-testid={'view-all-badges-button'}
          onClick={toggleShowAllBadges}
        >
          {showingLastThreeBadges ? 'View All' : 'Collapse'}
        </StyledBlueButton>
      </BadgesWrapper>
    </ClientWorkoutsWrapper>
  );
};

const StyledWorkoutMetricSummary = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
`;

const StyledWorkoutMetricNumber = styled.strong`
  font-weight: bold;
  font-size: 20px;
`;

const ClientWorkoutsWrapper = styled.div`
  padding: 50px;
  border-bottom: 1px solid ${(props): string => props.theme.grey};
  margin-top: 70px;
  display: flex;
`;

const BadgesWrapper = styled.div`
  margin-left: 64px;
`;
export default ClientWorkoutsSection;
