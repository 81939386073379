import React, { ChangeEvent, ReactElement, useState } from 'react';
import { NumberInput } from 'carbon-components-react';
import { capitalize, keys, roundTo2DP } from '../../utils/utils';
import styled from 'styled-components';
import {
  ClientMacros as ClientMacrosType,
  ClientDataInput,
  ClientMacrosInput,
  ClientDetail,
  Scalars,
} from '../../types/adminPortalApiSchema';
import {
  MAX_MACRO_VAL,
  MAX_MACRO_VAL_WATER,
  MIN_MACRO_VAL,
  MIN_MACRO_VAL_WATER,
} from '../../constants';
import { WithBlueBullet } from '../Icons/BlueBullet';
import { UpdateUserArgs } from '../../pages/Client/Client';

type Props = {
  macros: ClientMacrosType | null | undefined;
  stepMacros: number[];
  setStepMacros: (args: number[]) => void;
  updatePendingUserChanges: (args: Partial<ClientDataInput>) => void;
  pendingUserChanges: ClientDataInput;
};

type Entry = [string, string | number | undefined];

type HandleChangeArgs = {
  value: string;
  keyToUpdate: keyof ClientMacrosType;
};

const sortAlphabetically = (a: Entry, b: Entry): number => {
  return a[0].localeCompare(b[0]);
};

const STEP = 0.01;

const ClientMacroEnum = [
  'carb',
  'fat',
  'fish',
  'meat',
  'poultry',
  'sauce',
  'veg',
  'water',
];
export const ClientMacros = ({
  macros,
  stepMacros,
  setStepMacros,
  updatePendingUserChanges,
  pendingUserChanges,
}: Props): ReactElement => {
  const macrosToDisplay =
    pendingUserChanges.macros || (macros as ClientMacrosType);
  // Keep same order as enum

  const handleChange = ({ value, keyToUpdate }: HandleChangeArgs): void => {
    if (value) {
      const update: Partial<ClientMacrosType> = {
        [keyToUpdate]: parseFloat(parseFloat(value).toFixed(2)),
      };

      const newMacros: ClientMacrosType = {
        ...macrosToDisplay,
        ...update,
      };

      const someMacrosHaveChanged = keys(newMacros).some(
        (k) => macros && macros[k] !== newMacros[k]
      );

      updatePendingUserChanges({
        macros: someMacrosHaveChanged ? newMacros : null,
      });

      const updatedMacros = stepMacros;

      const indexToUpdate = ClientMacroEnum.indexOf(keyToUpdate);

      if (indexToUpdate > -1) {
        updatedMacros[indexToUpdate] = roundTo2DP(Number(value));
      }
      setStepMacros(updatedMacros);
    }
  };

  if (!macros) {
    return (
      <UserMacrosWrapper>
        <p>User has no macros</p>
      </UserMacrosWrapper>
    );
  }

  const macrosArr = Object.entries(macrosToDisplay).sort(sortAlphabetically);
  const leftHandSide = macrosArr.filter((x, i) => i % 2 === 0);
  const rightHandSide = macrosArr.filter((x, i) => i % 2 !== 0);

  function renderMacros(arr: [string, number | 'ClientMacros' | undefined][]) {
    return arr.map((macro) => {
      const [key, value] = macro;
      const MIN_VAL = key === 'water' ? MIN_MACRO_VAL_WATER : MIN_MACRO_VAL;
      const MAX_VAL = key === 'water' ? MAX_MACRO_VAL_WATER : MAX_MACRO_VAL;

      if (value == null) return null;
      const isValid = value >= MIN_VAL && value <= MAX_VAL;
      const isDirty =
        macros && macros[key as keyof ClientMacrosInput] !== value;
      const indexToUpdate = ClientMacroEnum.indexOf(key);

      return (
        <MacroItem key={key}>
          <StyledNumberInput
            isDirty={isDirty}
            invalidText={'Invalid Macro Value'}
            data-testid={'number-input'}
            invalid={!isValid}
            onChange={(e: ChangeEvent<HTMLInputElement> | any): void => {
              if (e.type === 'click') {
                handleChange({
                  value: e.imaginaryTarget.value,
                  keyToUpdate: key as keyof ClientMacrosType,
                });
              } else {
                handleChange({
                  value: e.target.value || '0',
                  keyToUpdate: key as keyof ClientMacrosType,
                });
              }
            }}
            disabled={false}
            id={`Client Macros ${key}`}
            label={
              isDirty ? (
                <WithBlueBullet
                  isLabel={true}
                  copy={`${capitalize(key)} ${
                    key === 'water' ? '(Liters)' : '(%)'
                  }`}
                />
              ) : (
                `${capitalize(key)} ${key === 'water' ? '(Liters)' : '(%)'}`
              )
            }
            size={undefined}
            step={STEP}
            min={MIN_VAL}
            max={MAX_VAL}
            value={stepMacros[indexToUpdate]}
          />
        </MacroItem>
      );
    });
  }

  return (
    <UserMacrosWrapper>
      <MacroLeftElements>{renderMacros(leftHandSide)}</MacroLeftElements>
      <div>{renderMacros(rightHandSide)}</div>
    </UserMacrosWrapper>
  );
};

const StyledNumberInput = styled(({ isDirty, ...rest }) => (
  <NumberInput {...rest} />
))`
  input[type='number'] {
    color: ${(props): string => {
      if (props.invalid) return props.theme.warningRed;
      return props.isDirty ? props.theme.inspireCobalt : 'inherit';
    }};
  }
`;

const UserMacrosWrapper = styled.div`
  display: flex;
  width: 50%;
`;

const MacroItem = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 24px;
`;

const MacroLeftElements = styled.div`
  margin-right: 100px;
  justify-content: space-evenly;
`;
