import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ContentfulError } from '../../types/adminPortalApiSchema';
import ContentfulErrorsAccordian from '../ContentfulErrorsAccordian/ContentfulErrorsAccordian';
import { Heading2 } from '../Theme/Theme';

export const ContentfulErrorsSection = ({
  errors,
}: {
  errors: ContentfulError[];
}): ReactElement => {
  return (
    <ContentfulErrorsWrapper>
      <div>
        <div>
          <Heading2>Error details</Heading2>
          <DetailsText>
            This recipe pack contains Contentful GraphQL errors. The product
            team may ask for this info to help troubleshoot the issue.
          </DetailsText>
        </div>
        <ContentfulErrorsAccordian errors={errors} />
      </div>
    </ContentfulErrorsWrapper>
  );
};

const ContentfulErrorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  margin-top: 70px;
`;

const DetailsText = styled.p`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;
