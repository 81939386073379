import React, { ReactElement, useState, useEffect } from 'react';
import './styles.scss';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { LoadingDialog } from '../Dialogs/Loading/Loading';

import { User } from './utils';
import { updateSuspendedAdmin } from './updateAdmin';
type Props = {
  user: User;
  hasBeenEdited: boolean;
  onChange: () => void;
};

export const UpdateAdminStatus = ({
  user,
  hasBeenEdited,
  onChange,
}: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  function handleChange() {
    // Here, we invoke the callback with the new value
    onChange();
  }
  const handleSaveChanges = (): void => {
    setLoading(false);
    setShowContinueDialog(true);
  };
  useEffect(() => {
    if (hasBeenEdited) {
      setShowConfirmationDialog(true);
    }
  }, [hasBeenEdited, onChange]);

  return (
    <div>
      {showConfirmationDialog && !isLoading && (
        <ConfirmationDialog
          headline={`Are you sure you want to ${
            !user.suspended ? 'unsuspend' : 'suspend'
          } ${user.name}`}
          byline={
            user.suspended
              ? `This will temporarily remove access for ${user.name} and can be undone.`
              : `This will restore access for ${user.name}`
          }
          dismissButtonText="Ignore changes"
          confirmButtonText={user.suspended ? 'Suspend' : 'Unsuspend'}
          confirmButtonColor={user.suspended ? 'red' : 'blue'}
          confirmButtonCallback={async (): Promise<void> => {
            setLoading(true);
            const { data } = await updateSuspendedAdmin({
              id: user.email,
              suspended: user.suspended,
            });

            setShowConfirmationDialog(false);
            if (data !== null) {
              setLoading(false);
              setShowContinueDialog(true);
            } else {
              setLoading(false);
              alert(
                `Unable to ${
                  !user.suspended ? 'Unsuspend' : 'Suspend'
                } admin user, please contact an administrator quoting adminId:${
                  user.id
                } to help resolve this.`
              );
            }
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
            handleChange();
          }}
          warning={user.suspended}
        />
      )}
      {isLoading && <LoadingDialog heading={'Saving your work'} />}
      {showContinueDialog && (
        <ContinueDialog
          headline={`${user.name}'s account has been ${
            !user.suspended ? 'unsuspended' : 'suspended'
          }`}
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
            handleChange();
          }}
        />
      )}
    </div>
  );
};
