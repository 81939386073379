import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import React, { ReactElement } from 'react';
import './styles.scss';
import { capitalize, isLastItemInArray, isUUID } from '../../utils/utils';
import styled from 'styled-components';

type MapUUIDToHumanReadable = {
  item: string;
  isClientDetailRoute: boolean | undefined;
};

const mapUUIDToHumanReadable = ({
  item,
  isClientDetailRoute,
}: MapUUIDToHumanReadable): string => {
  return isClientDetailRoute && isUUID(item)
    ? 'Client Detail'
    : capitalize(item);
};

export const BreadcrumbNav = (): ReactElement => {
  const { pathname } = useLocation();
  const isClientDetailRoute = useRouteMatch('/clients/:id')?.isExact;
  const items = pathname.split('/').filter(Boolean);
  const breadCrumbs: { text: string; path: string }[] = [
    { text: 'Home', path: '/' },
  ];

  for (let index = 0; index < items.length; index++) {
    const text = items[index];
    const path = '/' + items.slice(0, index + 1).join('/');
    breadCrumbs.push({ text, path });
  }

  return (
    <BreadcrumbWrapper>
      <Breadcrumb className="some-class" noTrailingSlash>
        {breadCrumbs.map((crumb, i, arr) => {
          return (
            <BreadcrumbItem key={crumb.text}>
              {isLastItemInArray(arr, i) ? (
                <span>
                  {mapUUIDToHumanReadable({
                    item: crumb.text,
                    isClientDetailRoute,
                  })}
                </span>
              ) : (
                <Link to={crumb.path}>
                  {mapUUIDToHumanReadable({
                    item: crumb.text,
                    isClientDetailRoute,
                  })}
                </Link>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </BreadcrumbWrapper>
  );
};

const BreadcrumbWrapper = styled.div`
  display: flex;
  margin-left: 99px;
  margin-top: 1rem;
  .bx--link {
    color: ${(props): string => props.theme.inspireCobalt};
    &:visited {
      color: ${(props): string => props.theme.inspireCobalt};
    }
  }
`;
