import { Accordion, AccordionItem } from 'carbon-components-react';
import React, { ReactElement } from 'react';
import { ContentfulError } from '../../types/adminPortalApiSchema';
import { BCStructuredList } from '../BCStructuredList/BCStructuredList';

const ContentfulErrorsAccordian = ({
  errors,
}: {
  errors: ContentfulError[];
}): ReactElement => {
  return (
    <Accordion>
      {errors.map((error, i) => {
        const locationsText = error.locations?.map(
          (location) => `(Col: ${location.column}, Line: ${location.line})`
        );

        return (
          <AccordionItem key={i} title={error.code ?? 'No error code'}>
            <BCStructuredList
              params={[
                { text: 'Code', value: error.code },
                { text: 'Message', value: error.message },
                { text: 'Details', value: error.details },
                { text: 'Path', value: error.path },
                { text: 'Locations', value: locationsText },
                { text: 'Request ID', value: error.requestId },
              ]}
            />
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default ContentfulErrorsAccordian;
