import React, { ReactElement } from 'react';
import styled from 'styled-components';

export const WithBlueBullet = ({
  copy,
  leftWidth,
  isLabel,
}: {
  copy: string;
  leftWidth?: BulletWidth;
  isLabel?: boolean;
}): ReactElement =>
  isLabel ? (
    <>
      <LabelBullet></LabelBullet>
      <LabelWords>{copy}</LabelWords>
    </>
  ) : (
    <>
      <StyledBullet width={leftWidth} />
      <Words>{copy}</Words>
    </>
  );

export const Bullet = styled.div`
  width: 7px;
  height: 7px;
  position: absolute;
  background: ${(props): string => props.theme.inspireCobalt};
  left: '5px';
  border-radius: 50%;
  margin-top: 6px;
`;

type BulletWidth = -5 | 2 | 5 | 70;

type BulletArgs = {
  width: BulletWidth;
};

const bulletConfig = ({ width }: BulletArgs): string => {
  const defaults = '5px';

  const config = width + 'px';

  return config || defaults;
};

export const StyledBullet = styled(({ width, ...rest }) => (
  <Bullet {...rest} />
))`
  left: ${(props): string => bulletConfig(props)};
`;

const Words = styled.span`
  color: ${(props): string => props.theme.inspireCobalt};
`;

const LabelBullet = styled.div`
  width: 7px;
  height: 7px;
  position: absolute;
  background: ${(props): string => props.theme.inspireCobalt};
  left: 2px;
  border-radius: 50%;
  margin-top: 4px;
`;

const LabelWords = styled.span`
  color: ${(props): string => props.theme.inspireCobalt};
  margin-left: 14px;
`;
