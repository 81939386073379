import {
  DataTable,
  OverflowMenuItem,
  OverflowMenu,
  Button,
} from 'carbon-components-react';

import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { capitalize } from '../../utils/utils';
import { DisplayAdmin, HEADINGS, User, adminGroups } from './utils';
import { Add16 } from '@carbon/icons-react';
import { UpdateAdminGroup } from './UpdateAdminGroup';
import { UpdateAdminStatus } from './UpdateAdminStatus';
import { RemoveAdmin } from './RemoveAdmin';

import { AddNewAdmin } from './AddNewAdmin';

const {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;

const AdminDataTable = ({
  rows,
  errors,
  username,
  onChange,
}: {
  rows: DisplayAdmin[];
  errors: [{ message: string }] | undefined;
  username: string;
  onChange: () => void;
}): ReactElement => {
  const [groupHasBeenEdited, setGroupHasBeenEdited] = useState(false);
  const [statusHasBeenEdited, setStatusHasBeenEdited] = useState(false);
  const [adminDeleted, setAdminDeleted] = useState(false);

  const [newAdmin, setNewAdmin] = useState(false);

  const newUser: User = {
    name: '',
    email: '',
    currentGroup: 'UNKNOWN',
    newGroup: 'UNKNOWN',
    id: '',
    suspended: false,
    deleted: false,
  };
  const [user, setUser] = useState<User>(newUser);
  function handleChange() {
    // Here, we invoke the callback with the new value
    onChange();
  }
  if (errors) {
    return (
      <>
        <NoResultText key={'errorMessages'}>
          Failed to get GQL data with the following errors:
        </NoResultText>

        {errors.map((err, index) => (
          <ErrorText key={'errorMessage' + index}>{err.message}</ErrorText>
        ))}
      </>
    );
  }
  if (rows.length === 0) {
    return <NoResultText>No result</NoResultText>;
  }
  function handleGroupChange(): void {
    setGroupHasBeenEdited(false);
    handleChange();
  }

  function handleStatusChange(): void {
    setStatusHasBeenEdited(false);
    handleChange();
  }
  function handleDeleteAdmin(): void {
    setAdminDeleted(false);
    handleChange();
  }

  function handleNewAdmin(): void {
    setNewAdmin(false);
    handleChange();
  }

  return (
    <div>
      <DataTable
        isSortable={true}
        key={'admin'}
        rows={rows}
        headers={HEADINGS}
        render={({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          onInputChange,
        }): ReactElement => (
          <div data-testid="data-table">
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={onInputChange} />
                <Button
                  renderIcon={Add16}
                  onClick={(): void => setNewAdmin(true)}
                >
                  Add new admin
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header, idx) => {
                    if (header.key === 'group') {
                      return (
                        <React.Fragment key={'empty'}>
                          <TableHeader
                            {...getHeaderProps({ header })}
                            key={header.key.toString()}
                          >
                            {capitalize(header.header.toString())}
                          </TableHeader>
                          <TableHeader key={'space'}></TableHeader>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <TableHeader {...getHeaderProps({ header })} key={idx}>
                          {capitalize(header.header.toString())}
                        </TableHeader>
                      );
                    }
                  })}
                  <TableHeader key={'null'} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const rowEmail = row.cells.filter(
                    (cell) => cell.id.split(':')[1] === 'email'
                  )[0].value;
                  const userIsSuspended = row.cells[3].value === 'SUSPENDED';

                  const isSameUser = rowEmail === username;
                  return (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => {
                        if (cell.id.split(':')[1] === 'group') {
                          return (
                            <React.Fragment key={row.id + 'react'}>
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                              <TableCell key={'groupOverflow' + cell.id}>
                                {isSameUser ? (
                                  <p />
                                ) : (
                                  <OverflowMenu key={'groupOverflowMenu'}>
                                    {adminGroups.map((group) => {
                                      return (
                                        <OverflowMenuItem
                                          key={group + cell.id}
                                          itemText={group}
                                          onClick={(): void => {
                                            setUser({
                                              name: row.cells[0].value,
                                              email: row.cells[1].value,
                                              currentGroup: row.cells[2].value,
                                              newGroup: group,
                                              id: row.id,
                                              suspended: userIsSuspended,
                                              deleted: false,
                                            });
                                            setGroupHasBeenEdited(true);
                                          }}
                                        />
                                      );
                                    })}
                                  </OverflowMenu>
                                )}
                              </TableCell>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <TableCell key={cell.id + 'false'}>
                              {cell.value}
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell key={row.id + 'cell'}>
                        {isSameUser ? (
                          <p />
                        ) : (
                          <OverflowMenu key={row.id + 'overflow'}>
                            <OverflowMenuItem
                              key={`${userIsSuspended} user ${row.id}`}
                              itemText={`${
                                userIsSuspended ? 'Unsuspend' : 'Suspend'
                              } user`}
                              onClick={(): void => {
                                setUser({
                                  name: row.cells[0].value,
                                  email: row.cells[1].value,
                                  currentGroup: row.cells[2].value,
                                  newGroup: row.cells[2].value,
                                  id: row.id,
                                  suspended: !userIsSuspended,
                                  deleted: false,
                                });
                                setStatusHasBeenEdited(true);
                              }}
                            />
                            <OverflowMenuItem
                              data-test-id={'delete-admin-button'}
                              key={'Delete user' + row.id}
                              itemText="Delete User"
                              hasDivider
                              isDelete
                              onClick={(): void => {
                                setUser({
                                  name: row.cells[0].value,
                                  email: row.cells[1].value,
                                  currentGroup: row.cells[2].value,
                                  newGroup: row.cells[2].value,
                                  id: row.id,
                                  suspended: userIsSuspended,
                                  deleted: true,
                                });
                                setAdminDeleted(true);
                              }}
                            />
                          </OverflowMenu>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      />
      <UpdateAdminGroup
        user={user}
        hasBeenEdited={groupHasBeenEdited}
        onChange={handleGroupChange}
      />
      <UpdateAdminStatus
        user={user}
        hasBeenEdited={statusHasBeenEdited}
        onChange={handleStatusChange}
      />
      <RemoveAdmin
        user={user}
        hasBeenEdited={adminDeleted}
        onChange={handleDeleteAdmin}
      />
      <AddNewAdmin
        hasBeenEdited={newAdmin}
        completedCallback={handleNewAdmin}
      />
    </div>
  );
};

const NoResultText = styled.span`
  margin-top: 50px;
  display: block;
`;

const ErrorText = styled.span`
  margin-top: 50px;
  display: block;
  color: red;
`;
export default AdminDataTable;
