import gql from 'fake-tag';
import { queryAPI } from '../../utils/queryApi';
import { ClientDetail } from '../../types/adminPortalApiSchema';

export async function fetchClient(
  id: string
): Promise<{
  data: { client: ClientDetail };
  errors?: [{ message: string }];
}> {
  return queryAPI<{ client: ClientDetail }>(
    gql`
      query GetClient($id: ID!) {
        client(id: $id) {
          email
          id
          subscription
          name
          sex
          subscriptionBillingFrequency
          createdAt
          initialWeight {
            measurement
            id
            timestamp
          }
          macros {
            fat
            carb
            meat
            veg
            water
            poultry
            fish
            sauce
          }
          cycles {
            ability
            bodyStats {
              measurement
              measurementType
            }
            completed
            cycleCreatedAt
            cycleId
            cycleNumber
            cycleUpdatedAt
            mealPlan
            recipePackNumber
          }
          dob
          height {
            measurement
          }
          trackedWorkouts {
            sessionId
            workoutId
            workoutIsWatched
            timestamp
            trainer
            trainingType
            fractionWatched
            secondsWatched
            workoutDuration
            rawData
          }
          badges {
            badgeId
            awardedDate
          }
        }
      }
    `,
    { id: id }
  );
}
