import { DataTableHeader } from 'carbon-components-react';
import { Scalars, AdminGroup } from '../../types/adminPortalApiSchema';

export const adminGroups: AdminGroup[] = [
  'ADMIN',
  'SUPERADMIN',
  'SUPPORT_HERO',
  'GDPR',
];

export const HEADINGS: DataTableHeader<string>[] = [
  {
    header: 'name',
    key: 'name',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'admin rights',
    key: 'group',
  },
  {
    header: 'status',
    key: 'status',
  },
];

export type DisplayAdmin = {
  /**   The admins email address */
  email: Scalars['String'];
  /**   The admins group */
  group: Scalars['String'];
  /**   The admins uuid */
  id: Scalars['ID'];
  /**   The admins name */
  name: Scalars['String'];
  /** The status of the admin user */
  status: Scalars['String'];
};

export type User = {
  name: string;
  email: string;
  currentGroup: AdminGroup;
  newGroup: AdminGroup;
  id: string;
  suspended: boolean;
  deleted: boolean;
};
