import { DataTableHeader } from 'carbon-components-react';
import { ExtendedClient } from './ClientDataTable';

export const HEADINGS: DataTableHeader<keyof ExtendedClient | 'details'>[] = [
  {
    header: 'name',
    key: 'name',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'userId',
    key: 'userId',
  },
  {
    header: 'subscription',
    key: 'subscription',
  },
  {
    header: 'details',
    key: 'details',
  },
];
