import React, { ReactElement, useEffect, useState } from 'react';
import { RecipePackSummary } from '../../types/adminPortalApiSchema';
import { Loader } from '../Loader/Loader';
import { fetchRecipePacks } from '../../queries/getRecipePacksQuery';
import styled from 'styled-components';
import RecipePacksDataTable from './RecipePacksDataTable';

export function sortRecipePacksBySequence(
  pack1: RecipePackSummary,
  pack2: RecipePackSummary
): number {
  return pack1.sequence > pack2.sequence ? -1 : 1;
}

export const RecipePacksTable = (): ReactElement => {
  const [rows, setRows] = useState<RecipePackSummary[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  const searchRecipePacks = async (): Promise<void> => {
    const res = await fetchRecipePacks();
    const { data, errors } = res;
    if (errors) {
      setRows([]);
    }
    if (data) {
      setRows([...data.recipePacks].sort(sortRecipePacksBySequence));
    } else {
      setRows([]);
    }
  };
  useEffect(() => {
    searchRecipePacks().then(() => setLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <RecipePacksDataTable rows={rows} />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  margin: 2rem auto auto;
  .bx--data-table td {
    vertical-align: middle;
  }
`;
