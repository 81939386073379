import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';
import { Admin, AdminGroup } from '../../types/adminPortalApiSchema';

export async function deleteAdmin(id: string) {
  return queryAPI<{ updateAdmin: Admin }>(
    gql`
      mutation DeleteAdmin($args: ID!) {
        updateAdmin(id: $args, deleted: true) {
          email
          id
          name
          group
          status
        }
      }
    `,
    { args: id }
  );
}

export async function updateSuspendedAdmin({
  id,
  suspended,
}: {
  id: string;
  suspended: boolean;
}) {
  return queryAPI<{ updateAdmin: Admin }>(
    gql`
      mutation updateSuspendedAdmin($id: ID!, $suspended: Boolean!) {
        updateAdmin(id: $id, suspended: $suspended) {
          email
          id
          name
          group
          status
        }
      }
    `,
    { id, suspended }
  );
}

export async function updateGroup({
  id,
  updatedGroup,
}: {
  id: string;
  updatedGroup: AdminGroup;
}) {
  return queryAPI<{ updateAdmin: Admin }>(
    gql`
      mutation updateGroup($id: ID!, $updatedGroup: AdminGroup) {
        updateAdmin(id: $id, updatedGroup: $updatedGroup) {
          email
          id
          name
          group
          status
        }
      }
    `,
    { id, updatedGroup }
  );
}
