import { AnySchema } from '@hapi/joi';

export default (values: any, schema: AnySchema) => {
  const { error } = schema.validate(values, { abortEarly: false });
  let formattedErrors;
  if (error) {
    formattedErrors = error.details.reduce(
      (
        acc: {
          [key: string]: string;
        },
        validationErrorItem
      ) => {
        acc[validationErrorItem.path.toString()] = validationErrorItem.message;
        return acc;
      },
      {}
    );
  }

  return formattedErrors;
};
