import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';
import {
  ClientDataInput,
  UpdatedClient,
} from '../../types/adminPortalApiSchema';

export async function updateClientData(client: ClientDataInput) {
  return queryAPI<{ clientDataInput: UpdatedClient }>(
    gql`
      mutation UserData($args: ClientDataInput!) {
        clientDataInput(clientDataInput: $args) {
          macros {
            fat
            carb
            meat
            veg
            water
            poultry
            fish
            sauce
          }
          id
          cycles {
            cycleId
            cycleNumber
            ability
            mealPlan
            recipePackNumber
            cycleCreatedAt
            cycleUpdatedAt
            completed
            bodyStats {
              measurement
              measurementType
            }
          }
          name
          initialWeight {
            id
            measurement
            timestamp
          }
        }
      }
    `,
    {
      args: {
        name: client.name,
        userId: client.userId,
        mealPlanAndRecipePackForCycle: client.mealPlanAndRecipePackForCycle,
        cycleNumberAndAbilityForCycle: client.cycleNumberAndAbilityForCycle,
        macros: client.macros,
        currentAbilityAndCycle: client.currentAbilityAndCycle,
        daysToCheckIn: client.daysToCheckIn,
        initialWeight: client.initialWeight,
      },
    }
  );
}
