import gql from 'fake-tag';
import { queryAPI } from '../../utils/queryApi';
import { Client, QueryClientsArgs } from '../../types/adminPortalApiSchema';

export async function fetchClients({
  id,
  email,
}: QueryClientsArgs): Promise<{
  data: { clients: Client[] };
}> {
  return queryAPI<{ clients: Client[] }>(
    gql`
      query GetUsers($id: ID, $email: String) {
        clients(id: $id, email: $email) {
          id
          email
          subscription
          name
        }
      }
    `,
    {
      email: email,
      id: id,
    }
  );
}
