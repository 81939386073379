import { Admin } from '../../types/adminPortalApiSchema';
import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';

export async function fetchAdmins(): Promise<{
  data: { admins: Admin[] };
  errors?: [{ message: string }];
}> {
  return queryAPI<{ admins: Admin[]; errors: [{ message: string }] }>(
    gql`
      query GetAdmins {
        admins {
          id
          email
          group
          name
          status
        }
      }
    `
  );
}
