import React, { ReactElement, useState, useEffect } from 'react';
import './styles.scss';
import { NewAdminDialog } from '../Dialogs/NewAdminUser/NewAdmin';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { createAdmin } from './createAdmin';
import { AdminInput } from '../../types/adminPortalApiSchema';
import { LoadingDialog } from '../Dialogs/Loading/Loading';

type Props = {
  hasBeenEdited: boolean;
  completedCallback: () => void;
};

export const AddNewAdmin = ({
  hasBeenEdited,
  completedCallback,
}: Props): ReactElement | null => {
  const [showNewAdminDialog, setShowNewAdminDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [newAdminUserEmail, setNewAdminUserEmail] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (hasBeenEdited) {
      setShowNewAdminDialog(true);
    }
  }, [hasBeenEdited, completedCallback]);

  return (
    <div>
      {showNewAdminDialog && !isLoading && (
        <NewAdminDialog
          headline={`Add new admin`}
          dismissButtonText="Cancel"
          confirmButtonText="Send invite"
          addNewAdminCallback={async (
            adminInput: AdminInput
          ): Promise<void> => {
            setLoading(true);
            const { data } = await createAdmin(adminInput);
            setShowNewAdminDialog(false);
            if (!data) {
              setLoading(false);
              alert(
                'Unable to create a new admin user, please contact an administrator to help resolve this.'
              );
            } else {
              setLoading(false);
              setNewAdminUserEmail(adminInput.email);
              setShowContinueDialog(true);
            }
          }}
          dismissButtonCallback={(): void => {
            setShowNewAdminDialog(false);
            completedCallback();
          }}
        />
      )}
      {isLoading && <LoadingDialog heading={'Saving your work'} />}
      {showContinueDialog && (
        <ContinueDialog
          headline={'Your admin user has been created'}
          byline={`An email has been sent to ${newAdminUserEmail} with sign in details`}
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
            completedCallback();
          }}
        />
      )}
    </div>
  );
};
