import React from 'react';
import { CacheInvalidation } from '../../components/CacheInvalidation/CacheInvalidation';
import { Heading } from '../../components/Theme/Theme';

export function ContentInvalidate() {
  return (
    <>
      <Heading>Invalidate the cache</Heading>
      <div>
        Invalidating the contentful cache will ensure the latest version of
        content is available on the live production and internal apps.
      </div>
      <br />
      <div>
        This is useful if you have made some corrections to content (e.g. intro
        content, recipes and/or workouts) or have uploaded new content that is
        ready for proofing.
      </div>

      <CacheInvalidation />
    </>
  );
}
