export type ThemeFontSizes = {
  xl: string;
  l: string;
  m: string;
  s: string;
};
export const fontSizes = {
  xl: '2rem',
  l: '1.5rem', // 24px
  m: '1rem', // 16px
  s: '0.875rem', // 12px
};
