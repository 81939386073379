import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import './styles.scss';
import { Heading2 } from '../Theme/Theme';
import { ClientDetail } from '../../types/adminPortalApiSchema';
import { StyledBlueButton } from '../Button/Button';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { exportClientData } from './exportClientData';

type Props = {
  user: ClientDetail;
};

export const ClientDataExportSection = ({
  user,
}: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  return (
    <ClientDataExportWrapper>
      <div>
        <Heading2>Client data request</Heading2>
        <p>
          Export and send all data and any transformation photos directly to the
          client
        </p>
      </div>
      <DataExportButton
        data-testid={'export-client-data-button'}
        onClick={(): void => {
          setShowConfirmationDialog(true);
        }}
        kind="secondary"
        size="default"
        tabIndex={0}
        type="button"
      >
        Send to client
      </DataExportButton>
      {showConfirmationDialog && (
        <ConfirmationDialog
          headline={
            'Are you sure you want to export and send all data and any transformation photos directly to the client?'
          }
          byline=""
          dismissButtonText="Cancel"
          confirmButtonColor="blue"
          confirmButtonText="Send to client"
          confirmButtonCallback={async (): Promise<void> => {
            return exportClientData(user.id)
              .then((response) => {
                const success = response?.data?.exportClientData;
                if (success === true) {
                  setShowConfirmationDialog(false);
                  setShowContinueDialog(true);
                } else {
                  throw new Error('Received a response, but was not success.');
                }
              })
              .catch((err) => {
                console.log({ err });
                setShowConfirmationDialog(false);
                alert(
                  `Exporting data for this account failed, please contact an administrator to help resolve this, quoting User ID: ${user.id}.`
                );
              });
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
          }}
          warning={false}
        ></ConfirmationDialog>
      )}
      {showContinueDialog && (
        <ContinueDialog
          headline={'The client data request has been sent!'}
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
          }}
        ></ContinueDialog>
      )}
    </ClientDataExportWrapper>
  );
};

const ClientDataExportWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  margin-top: 70px;
`;

export const DataExportButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  width: 178px;
  height: 48px;
`;
