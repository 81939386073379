import React, { ReactElement } from 'react';
import styled from 'styled-components';
import InfoSVG from './circled_i_icon.svg';
import { Loader } from '../../Loader/Loader';

export const LoadingDialog = ({
  heading,
}: {
  heading?: string;
}): ReactElement | null => {
  const headingText = heading ?? 'Loading';
  return (
    <LoadingTakeover>
      <LoadingWrapper>
        <InfoIconImg src={InfoSVG} alt={headingText}></InfoIconImg>
        <Heading>{headingText}</Heading>
        <Loader />
      </LoadingWrapper>
    </LoadingTakeover>
  );
};

const LoadingTakeover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 465px;
  width: 352px;
  position: relative;
`;

const InfoIconImg = styled.img`
  padding-top: 50px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
`;
