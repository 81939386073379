import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { StyledLogoSpring } from '../Logo/LogoSpring';
import { LogoHeading } from '../Logo/LogoHeading';
import { Nav } from '../Nav/Nav';
import { UserAuth } from '../UserAuth/UserAuth';

type HeaderProps = {
  isLoggedIn: boolean;
};

export function Header(props: HeaderProps): ReactElement {
  const { isLoggedIn } = props;
  return (
    <StyledHeader>
      <StyledLogo>
        <StyledLogoSpring />
        <LogoHeading />
      </StyledLogo>
      {isLoggedIn && <Nav />}
      <UserAuth isLoggedIn={isLoggedIn} />
    </StyledHeader>
  );
}

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 58px;
`;

const StyledHeader = styled.header`
  background: ${(props): string => props.theme.stimulateInk};
  padding-left: ${(props): string => props.theme.contentPaddingLeft};
  color: ${(props): string => props.theme.inspireCobalt};
  display: flex;
  height: 69px;
  align-items: center;
  width: 100%;
`;
