import { createGlobalStyle, css } from 'styled-components';
import { colors } from '../components/Theme/colors';
import './fonts.scss';

export const globalStyles = css`
  body {
    font-family: 'Lato', sans-serif;
    display: inherit;
    overflow-y: scroll;
    font-size: 0.9rem;
    color: ${colors.bodyCopy};
  }
`;
export const GlobalStyles = createGlobalStyle`${globalStyles}`;
