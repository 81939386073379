import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import './styles.scss';
import { Heading2 } from '../Theme/Theme';
import {
  ClientDataInput,
  ClientDetail,
  ClientMacros as ClientMacrosType,
} from '../../types/adminPortalApiSchema';
import { ClientMacros } from '../ClientMacros/ClientMacros';
import { ClientSaveSection } from './ClientSaveSection';

import { UpdateUserArgs } from '../../pages/Client/Client';
import { StyledBlueButton } from '../Button/Button';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { updateClientData } from './updateClientData';
import { LoadingDialog } from '../Dialogs/Loading/Loading';
import { roundTo2DP } from '../../utils/utils';

type Props = {
  user: ClientDetail;
  hasBeenEdited: boolean;
  updateUser: (args: UpdateUserArgs) => void;
  isValid: boolean;
  updatePendingUserChanges: (args: Partial<ClientDataInput>) => void;
  pendingUserChanges: ClientDataInput;
};

const calculateNewMacros = ({
  user,
  increasePortion,
}: {
  user: ClientDetail;
  increasePortion: boolean;
}): ClientMacrosType | null | undefined => {
  const originalMacros = user.macros;
  if (!user.macros) {
    return originalMacros;
  }
  let adjustment = 0.05;
  if (!increasePortion) {
    adjustment = -0.05;
  }
  const {
    macros: { carb, fat, meat, fish, poultry, sauce, water, veg },
  } = user;
  const updatedMacro: ClientMacrosType = {
    carb: roundTo2DP(carb + adjustment),
    fat: roundTo2DP(fat + adjustment),
    meat: roundTo2DP(meat + adjustment),
    fish: roundTo2DP(fish + adjustment),
    poultry: roundTo2DP(poultry + adjustment),
    sauce: roundTo2DP(sauce + adjustment),
    water: water,
    veg: roundTo2DP(veg + adjustment),
  };
  return updatedMacro;
};

export const ClientMacrosSection = ({
  user,
  updateUser,
  hasBeenEdited,
  isValid,
  pendingUserChanges,
  updatePendingUserChanges,
}: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [increasePortion, setIncreasePortion] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const macrosToDisplay =
    pendingUserChanges.macros || (user.macros as ClientMacrosType);
  const [displayedMacros, setDisplayedMacros] = useState(macrosToDisplay);
  const defaultMacroSteps = macrosToDisplay
    ? [
        macrosToDisplay.carb,
        macrosToDisplay.fat,
        macrosToDisplay.fish,
        macrosToDisplay.meat,
        macrosToDisplay.poultry,
        macrosToDisplay.sauce,
        macrosToDisplay.veg,
        macrosToDisplay.water,
      ]
    : [];
  const [stepMacros, setStepMacros] = useState(defaultMacroSteps);

  return (
    <ClientMacrosWrapper>
      <div>
        <Heading2>Client Macros</Heading2>
        <p>View and edit client macros</p>
        {user.macros && (
          <MacrosButton
            data-testid={'increase-portion-size-button'}
            onClick={(): void => {
              setIncreasePortion(true);
              setShowConfirmationDialog(true);
            }}
            kind="secondary"
            size="default"
            tabIndex={0}
            type="button"
          >
            Increase Portion size
          </MacrosButton>
        )}
        {user.macros && (
          <MacrosButton
            data-testid={'decrease-portion-size-button'}
            onClick={(): void => {
              setIncreasePortion(false);
              setShowConfirmationDialog(true);
            }}
            kind="secondary"
            size="default"
            tabIndex={0}
            type="button"
          >
            Decrease Portion size
          </MacrosButton>
        )}
        {isLoading && <LoadingDialog heading={'Updating portion feedback'} />}
        {showConfirmationDialog && !isLoading && (
          <ConfirmationDialog
            headline={`Are you sure you want to ${
              increasePortion ? 'increase' : 'decrease'
            } the portion size for the client?
            
            This will remove any unsaved macro changes`}
            byline=""
            dismissButtonText="Cancel"
            confirmButtonColor="blue"
            confirmButtonText={`${
              increasePortion ? 'Increase' : 'Decrease'
            } portion size`}
            confirmButtonCallback={async (): Promise<void> => {
              const updatedMacros = calculateNewMacros({
                user,
                increasePortion,
              }) as ClientMacrosType;
              setLoading(true);
              const updatedUser: ClientDataInput = {
                userId: user.id,
                macros: updatedMacros,
              };

              if (updatedMacros) {
                updatedMacros.water = user.macros?.water || 0;
              }

              updateClientData(updatedUser)
                .then((res) => {
                  setLoading(false);
                  updateUser({
                    update: {
                      macros: updatedMacros,
                    },
                  });
                  setStepMacros([
                    updatedMacros.carb,
                    updatedMacros.fat,
                    updatedMacros.fish,
                    updatedMacros.meat,
                    updatedMacros.poultry,
                    updatedMacros.sauce,
                    updatedMacros.veg,
                    updatedMacros.water,
                  ]);
                  updatePendingUserChanges({
                    ...pendingUserChanges,
                    macros: undefined,
                  });

                  setDisplayedMacros(updatedMacros as ClientMacrosType);
                })
                .catch((err) => {
                  console.log({ err });
                  setLoading(false);
                  alert(
                    `Updating portion feedback failed. Please contact an administrator to help resolve this, quoting User ID: ${user.id}.`
                  );
                });
              setShowConfirmationDialog(false);
              setShowContinueDialog(true);
            }}
            dismissButtonCallback={(): void => {
              setShowConfirmationDialog(false);
            }}
            warning={false}
          />
        )}
        {showContinueDialog && !isLoading && (
          <ContinueDialog
            headline={'The portion feedback has been saved!'}
            continueButtonText="Continue"
            continueButtonCallback={(): void => {
              setShowContinueDialog(false);
            }}
          ></ContinueDialog>
        )}
        <ClientSaveSection
          user={user}
          hasBeenEdited={hasBeenEdited}
          updateUser={updateUser}
          isValid={isValid}
          pendingUserChanges={pendingUserChanges}
          updatePendingUserChanges={updatePendingUserChanges}
          genericError="Failed to update macros"
        />
      </div>
      <ClientMacros
        macros={displayedMacros}
        stepMacros={stepMacros}
        setStepMacros={setStepMacros}
        updatePendingUserChanges={updatePendingUserChanges}
        pendingUserChanges={pendingUserChanges}
      />
    </ClientMacrosWrapper>
  );
};

const ClientMacrosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
`;

export const MacrosButton = styled((props) => <StyledBlueButton {...props} />)`
  width: 178px;
  height: 48px;
`;
