import './styles.scss';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import merge from 'deepmerge';
import { ClientStatsSection } from '../../components/ClientSections/ClientStatsSection';
import { fetchClient } from './getClientQuery';

import { Loader } from '../../components/Loader/Loader';
import {
  ClientDataInput,
  ClientDetail,
  UpdatedClient,
} from '../../types/adminPortalApiSchema';
import { ClientProfileSection } from '../../components/ClientSections/ClientProfileSection';
import { ClientMacrosSection } from '../../components/ClientSections/ClientMacrosSection';
import { ClientDeleteSection } from '../../components/ClientSections/ClientDeleteSection';
import { ClientDataExportSection } from '../../components/ClientSections/ClientDataExportSection';
import { ClientSaveModal } from '../../components/ClientSections/ClientSaveModal';

import { NestedPartial } from '../../types/global';
import {
  MAX_MACRO_VAL,
  MAX_MACRO_VAL_WATER,
  MIN_MACRO_VAL,
  MIN_MACRO_VAL_WATER,
} from '../../constants';
import deepmerge from 'deepmerge';
import { ClientMealPlanAndCycleSection } from '../../components/ClientSections/ClientMealPlanAndCycleSection';
import ClientWorkoutsSection from '../../components/ClientSections/ClientWorkoutsSection';

export type PartialClientUpdate = NestedPartial<ClientDetail | UpdatedClient>;

function overwriteMerge<U extends any[]>(
  destinationArray: U,
  sourceArray: U
): U {
  return sourceArray;
}

const defaultOptions: deepmerge.Options = {
  arrayMerge: overwriteMerge,
};
export type UpdateUserArgs = {
  update: PartialClientUpdate;
  options?: deepmerge.Options;
};

export function Client(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [
    ephemeralClientData,
    setEphemeralClientData,
  ] = useState<null | ClientDetail>(null);
  const [clientData, setClientDataFromServer] = useState<null | ClientDetail>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingUserChanges, setPendingUserChanges] = useState<ClientDataInput>(
    { userId: id }
  );
  const [hasBeenEdited] = useState(
    JSON.stringify(ephemeralClientData) !== JSON.stringify(clientData)
  );
  const [error, setError] = useState<string | null>(null);

  let isMacrosInputsValid = true;

  if (hasBeenEdited && ephemeralClientData?.macros) {
    const { water, ...rest } = ephemeralClientData?.macros;
    const foodMacrosValid = Object.values(rest).every(
      (macro) => macro >= MIN_MACRO_VAL && macro <= MAX_MACRO_VAL
    );
    const waterMacrosValid =
      water >= MIN_MACRO_VAL_WATER && water <= MAX_MACRO_VAL_WATER;
    isMacrosInputsValid = foodMacrosValid && waterMacrosValid;
  }

  useEffect(() => {
    fetchClient(id)
      .then((res) => {
        const { data, errors } = res;
        if (data && data.client) {
          setLoading(false);
          setEphemeralClientData(data.client);
          setClientDataFromServer(data.client);
        } else {
          if (errors && errors.length > 0) {
            const errorMessages = errors.map((e) => e.message);
            setLoading(false);
            console.log(errorMessages.join(', '));
            setError('Something went wrong');
          }
        }
      })
      .catch((e) => console.log('Something went wrong, handle error', e));
    return (): void => {
      setEphemeralClientData(null);
      setClientDataFromServer(null);
    };
  }, [setEphemeralClientData, id]);

  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  if (!ephemeralClientData || !clientData)
    return <p>Could not find client info. Something went wrong</p>;

  const updateUser = ({
    update,
    options = defaultOptions,
  }: UpdateUserArgs): void => {
    if (ephemeralClientData) {
      setEphemeralClientData(
        merge<ClientDetail, PartialClientUpdate>(
          ephemeralClientData,
          update,
          options
        )
      );
      setClientDataFromServer(
        merge<ClientDetail, PartialClientUpdate>(
          ephemeralClientData,
          update,
          options
        )
      );
    }
  };

  const updatePendingUserChanges = (
    clientDataInput: Partial<ClientDataInput>
  ): void => {
    setPendingUserChanges({
      ...pendingUserChanges,
      ...clientDataInput,
    });
  };
  return (
    <>
      {ephemeralClientData && (
        <>
          <ClientProfileSection
            user={ephemeralClientData}
            hasBeenEdited={
              typeof pendingUserChanges.daysToCheckIn === 'number' ||
              pendingUserChanges.currentAbilityAndCycle != null ||
              pendingUserChanges.name != null ||
              pendingUserChanges.initialWeight != null
            }
            isValid={isMacrosInputsValid}
            updateUser={updateUser}
            updatePendingUserChanges={updatePendingUserChanges}
            pendingUserChanges={pendingUserChanges}
          />
          <ClientMealPlanAndCycleSection
            user={ephemeralClientData}
            hasBeenEdited={
              (pendingUserChanges.mealPlanAndRecipePackForCycle != null &&
                pendingUserChanges.mealPlanAndRecipePackForCycle.length > 0) ||
              (pendingUserChanges.cycleNumberAndAbilityForCycle != null &&
                pendingUserChanges.cycleNumberAndAbilityForCycle.length > 0)
            }
            isValid={isMacrosInputsValid}
            updateUser={updateUser}
            updatePendingUserChanges={updatePendingUserChanges}
            pendingUserChanges={pendingUserChanges}
          />
          <ClientStatsSection
            user={ephemeralClientData}
            updateUser={updateUser}
          />
          <ClientMacrosSection
            user={ephemeralClientData}
            hasBeenEdited={pendingUserChanges.macros != null}
            isValid={isMacrosInputsValid}
            updateUser={updateUser}
            updatePendingUserChanges={updatePendingUserChanges}
            pendingUserChanges={pendingUserChanges}
          />
          <ClientWorkoutsSection user={ephemeralClientData} />
          <ClientDataExportSection user={ephemeralClientData} />
          <ClientDeleteSection user={ephemeralClientData} />
          <ClientSaveModal
            user={ephemeralClientData}
            hasBeenEdited={hasBeenEdited}
            updateUser={updateUser}
            pendingUserChanges={pendingUserChanges}
          />
        </>
      )}
    </>
  );
}
