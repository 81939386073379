import Joi from '@hapi/joi';
import QRCode from 'qrcode.react';
import React, { ReactElement, useState, useEffect } from 'react';

import { TextInput } from 'carbon-components-react';
import './styles.scss';
import styled from 'styled-components';
import Auth from '@aws-amplify/auth';
import { Form, Field } from 'react-final-form';
import { ErrorMessage, Theme } from '../../components/Theme/Theme';
import validate from '../../utils/validate';
import { StyledBlueButton } from '../../components/Button/Button';

export function MfaSetupFrame(props: {
  user: any;
  setUserStatus: React.Dispatch<React.SetStateAction<string>>;
}): ReactElement {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [qr, setQr] = useState<string>('');

  const handleMFASubmission = async ({
    secretkey,
  }: {
    secretkey: string;
  }): Promise<void> => {
    try {
      await Auth.verifyTotpToken(props.user, secretkey);
      props.setUserStatus('SUCCESSFUL_SETUP');
    } catch (err) {
      setErrorMessage(`
      Something went wrong. Could not set up MFA -
      ${err.message}
    `);
    }
  };

  useEffect(() => {
    const generateQRCode = async (): Promise<void> => {
      try {
        const code = await Auth.setupTOTP(props.user);
        const qrcode = `otpauth://totp/TheBodyCoach:${props.user.username}?secret=${code}&issuer=TheBodyCoach`;
        setQr(qrcode);
        setCode(code);
      } catch (err) {
        setErrorMessage(`
          Something went wrong. Could not set up a one-time password -
          ${err.message}
        `);
      }
    };

    if (props.user) generateQRCode();
  }, [props.user]);

  return (
    <Theme>
      {qr && (
        <Form
          onSubmit={handleMFASubmission}
          validate={(values) =>
            validate(
              values,
              Joi.object({
                secretkey: Joi.string()
                  .regex(/\d+/)
                  .min(6)
                  .max(6)
                  .required()
                  .messages({
                    'string.pattern.base': 'Secret key must be numbers only',
                  }),
              })
            )
          }
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <IntroText>
                Set up multifactor authentication <br />
                <SmallIntro>
                  Use the Google Authenticator app to scan the QR code below and
                  enter the secret key.
                </SmallIntro>
              </IntroText>
              <QRCode
                value={qr}
                data-cy="mfasetup-code-qr"
                data-secret={code}
              />
              <Field name="secretkey">
                {({ input, meta }) => (
                  <>
                    <InputWrapper>
                      <TextInput
                        {...input}
                        id="secretkey"
                        invalid={meta.error && meta.touched}
                        invalidText="Please enter the Code from your Authenticator"
                        labelText="Secret key"
                        light={false}
                        placeholder="Enter secret key"
                        size={undefined}
                        type="text"
                        data-testid="mfasetup-code-textfield"
                        required
                      />
                    </InputWrapper>
                  </>
                )}
              </Field>
              <StyledConfirmButton
                disabled={submitting}
                type="submit"
                data-testid="mfasetup-code-submit"
              >
                Save
              </StyledConfirmButton>
              {code && (
                <>
                  <SmallOutro>Can&apos;t scan it?</SmallOutro>
                  <OutroText>
                    Type this code into your authenticator instead:<br></br>
                    {code}
                  </OutroText>
                </>
              )}
            </form>
          )}
        />
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Theme>
  );
}

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 14px auto auto;
  font-weight: bold;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 30px;
`;
const SmallIntro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props): string => props.theme.m};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const IntroText = styled.h1`
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const SmallOutro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.m};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const OutroText = styled.span`
  margin-top: 10px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  word-break: break-all;
  text-align: center;
  line-height: 18px;
  letter-spacing: 0.16px;
`;
