import './styles.scss';

import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ContentfulErrorsSection } from '../../components/ContentfulErrorsSection/ContentfulErrorsSection';
import { Loader } from '../../components/Loader/Loader';
import { RecipePackHeader } from '../../components/RecipePackHeader/RecipePackHeader';
import { RecipePackTable } from '../../components/RecipePackTable/RecipePackTable';
import { RecipePackValidation } from '../../types/adminPortalApiSchema';
import { fetchRecipePack } from './getRecipePackQuery';
import { HorizontalRule } from '../../components/Theme/Theme';

interface ContentRecipePackParams {
  id: string;
}

export function ContentRecipePack(): ReactElement {
  const { id } = useParams<ContentRecipePackParams>();

  const [packValidation, setPackValidation] = useState<RecipePackValidation>();
  const [isLoading, setLoading] = useState<boolean>(true);

  function isRecipePackHealthy(): boolean {
    if (packValidation) {
      const noBadRecipes =
        packValidation.recipes.filter(
          (recipe) =>
            recipe.ingredientsListsMatch === false ||
            recipe.secondaryIngredientsListsMatch === false
        ).length === 0;
      const noGraphQLErrors = packValidation.contentfulErrors.length === 0;

      return noBadRecipes && noGraphQLErrors;
    }

    return false;
  }

  useEffect(() => {
    const searchRecipePack = async (): Promise<void> => {
      const res = await fetchRecipePack(id);
      const { data, errors } = res;
      if (errors) {
        console.log('errors!');
        setPackValidation(undefined);
      }
      if (data) {
        setPackValidation(data.recipePackValidation);
      } else {
        console.log('got no rows');
        setPackValidation(undefined);
      }
    };

    searchRecipePack().then(() => setLoading(false));
  }, [id]);

  function renderContent(): JSX.Element {
    if (packValidation) {
      return (
        <>
          <RecipePackHeader
            summary={packValidation}
            isHealthy={isRecipePackHealthy()}
          />
          <HorizontalRule />
          <Wrapper>
            <RecipePackTable recipes={packValidation.recipes} />
          </Wrapper>
          {packValidation.contentfulErrors.length > 0 && (
            <ContentfulErrorsSection errors={packValidation.contentfulErrors} />
          )}
        </>
      );
    }

    return (
      <Wrapper>
        <p>Error loading content!</p>
      </Wrapper>
    );
  }

  return <>{isLoading ? <Loader /> : renderContent()}</>;
}

const Wrapper = styled.div`
  margin: 2rem auto auto;
  .bx--data-table td {
    vertical-align: middle;
  }
`;
