import React from 'react';
import styled from 'styled-components';

const LogoWithSpring = () => {
  return (
    <svg
      width="47"
      height="43"
      viewBox="0 0 47 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0362 43C26.6207 43 26.2663 42.6821 26.2306 42.2601C26.1922 41.8146 26.5223 41.4217 26.9677 41.3833C37.4015 40.4811 45.2695 35.7847 45.2695 30.459C45.2695 27.6682 43.3264 25.1296 39.7985 23.3122C39.4008 23.1078 39.2442 22.6192 39.4496 22.2216C39.654 21.8239 40.1426 21.6673 40.5402 21.8727C44.6346 23.9818 46.889 27.0314 46.889 30.459C46.889 36.7328 38.57 42.006 27.1074 42.9963C27.084 42.9991 27.0596 43 27.0362 43ZM20.8759 26.4735C24.7152 25.6567 30.3109 24.0709 35.1696 21.1506C37.3443 19.8443 39.2714 18.1122 40.5956 16.2732C42.2273 14.0085 42.8922 11.6499 42.5199 9.45084C42.1176 7.07637 40.574 4.95417 38.0551 3.31305C35.3815 1.57347 31.7241 0.468757 27.4797 0.121778C22.7786 -0.262713 17.5468 0.264321 11.9294 1.68787C11.4887 1.79947 11.2224 2.24679 11.3339 2.68755C11.4455 3.12831 11.8929 3.39464 12.3336 3.28304C17.7746 1.9045 22.8255 1.39247 27.3456 1.76196C31.319 2.08643 34.711 3.10111 37.1567 4.69347C39.2799 6.0767 40.574 7.81722 40.8975 9.72748C41.5418 13.5283 38.0589 17.4951 34.3218 19.7411C25.7917 24.867 13.9269 26.3497 8.03389 26.0946H8.0217C4.68788 25.9989 2.56381 25.2572 1.87923 23.9508C1.5707 23.3619 1.56882 22.6154 1.87454 21.9018C2.49535 20.452 4.27995 19.2413 6.90011 18.4911C7.33712 18.3663 7.59032 17.9106 7.46466 17.4736C7.33993 17.0366 6.88417 16.7834 6.44716 16.909C2.46722 18.0475 0.940506 19.9005 0.360956 21.2538C-0.140757 22.4241 -0.118251 23.6864 0.420974 24.7151C1.42628 26.6329 3.89546 27.6222 7.96825 27.7395C8.44464 27.7601 8.93791 27.7704 9.44619 27.7704C12.8644 27.7685 16.9391 27.3109 20.8759 26.4735Z"
        fill="#0053C7"
      />
      <path
        d="M20.5804 23.7136C22.456 23.7136 23.8927 22.337 23.8927 20.5317C23.8927 18.7153 22.456 17.3498 20.5804 17.3498C18.7049 17.3498 17.2682 18.7153 17.2682 20.5317C17.2682 22.336 18.7049 23.7136 20.5804 23.7136ZM20.5804 19.036C21.3991 19.036 22.0171 19.6774 22.0171 20.5317C22.0171 21.3861 21.4001 22.0275 20.5804 22.0275C19.773 22.0275 19.1559 21.3861 19.1559 20.5317C19.1559 19.6774 19.773 19.036 20.5804 19.036Z"
        fill="white"
      />
      <path
        d="M11.8066 22.7749C12.1629 23.3807 12.8034 23.7127 13.6109 23.7127C15.2614 23.7127 16.5311 22.3717 16.5311 20.5308C16.5311 18.6909 15.2604 17.3489 13.6109 17.3489C12.8034 17.3489 12.1629 17.6809 11.8066 18.2867V15.5202H9.94321V23.5692H11.8066V22.7749ZM11.8066 20.3301C11.9013 19.5827 12.4837 19.036 13.2311 19.036C14.0385 19.036 14.6556 19.6774 14.6556 20.5317C14.6556 21.386 14.0385 22.0275 13.2311 22.0275C12.4827 22.0275 11.9013 21.4817 11.8066 20.7333V20.3301Z"
        fill="white"
      />
      <path
        d="M21.7912 26.9499C21.0616 26.8542 20.2701 26.9536 19.6521 27.279L19.4495 28.8208C20.0337 28.4785 20.648 28.3556 21.2604 28.4363C22.1316 28.5507 22.6286 29.0477 22.5404 29.7182L22.4954 30.0596C22.1785 29.7426 21.6833 29.4978 21.1188 29.4238C19.895 29.2625 18.929 29.8664 18.7809 30.9955C18.6336 32.1133 19.3604 33.0473 20.5139 33.1993C21.2079 33.2912 21.8305 33.0258 22.17 32.5316L22.0734 33.2612L23.9218 33.504L24.401 29.8673C24.5979 28.3725 23.5214 27.1778 21.7912 26.9499ZM22.3623 31.0724C22.2525 31.6322 21.7883 31.9783 21.2585 31.9089C20.7877 31.847 20.4942 31.5328 20.5402 31.1802C20.587 30.8267 20.9406 30.5979 21.4226 30.6616C21.7405 30.7038 22.0697 30.8426 22.367 31.0377L22.3623 31.0724Z"
        fill="white"
      />
      <path
        d="M28.9942 27.898C27.0408 27.6411 25.4532 28.6895 25.2009 30.6073C24.9496 32.5138 26.2099 33.9486 28.1633 34.2065C28.6341 34.2684 29.4425 34.2196 30.0164 33.8642L30.2593 32.0168C29.7604 32.4416 29.1246 32.6338 28.4775 32.5485C27.5716 32.4294 26.9489 31.7007 27.0605 30.853C27.1721 30.0052 27.9617 29.4632 28.8686 29.5823C29.5156 29.6676 30.0483 29.9893 30.4215 30.5173L30.6616 28.6933C30.1899 28.1869 29.465 27.9599 28.9942 27.898Z"
        fill="white"
      />
      <path
        d="M35.3852 28.7392C34.6556 28.6435 33.9466 28.9333 33.5415 29.466L33.8885 26.8317L32.0523 26.5898L31.0189 34.4372L32.855 34.6791L33.2705 31.5244C33.3746 30.7357 33.9279 30.2584 34.5759 30.3428C35.2229 30.4281 35.6187 30.9711 35.5193 31.7242L35.0917 34.9726L36.9278 35.2146L37.3883 31.7185C37.6058 30.0615 36.7975 28.9258 35.3852 28.7392Z"
        fill="white"
      />
      <path
        d="M12.3316 10.611L13.2732 10.4872L13.5414 12.5231C13.7786 14.3236 14.9565 14.9951 16.3219 14.815C16.7223 14.7625 17.1922 14.605 17.5842 14.3977L17.3732 12.7969C17.1781 12.9545 16.8996 13.1111 16.4645 13.1683C15.9937 13.2302 15.4826 12.9864 15.3776 12.1864L15.1215 10.2443L17.0046 9.9967L16.8049 8.47843L14.9218 8.72601L14.7146 7.14866L12.8662 7.39154L13.0734 8.96889L12.1319 9.09268L12.3316 10.611Z"
        fill="white"
      />
      <path
        d="M21.446 7.72255C20.7164 7.8182 20.1068 8.28241 19.8527 8.90228L19.5385 6.51281L17.7024 6.75476L18.703 14.3583L20.5392 14.1163L20.1237 10.9616C20.0196 10.173 20.4313 9.56811 21.0784 9.48277C21.7254 9.39743 22.2478 9.81943 22.3472 10.5734L22.7748 13.8219L24.611 13.5799L24.1506 10.0839C23.933 8.42495 22.8583 7.53687 21.446 7.72255Z"
        fill="white"
      />
      <path
        d="M28.7035 13.1945C29.751 13.0566 30.4965 12.719 30.9429 12.1929L30.7413 10.6625C30.1777 11.2036 29.5278 11.5412 28.8451 11.6303C27.7976 11.7681 27.128 11.318 26.8448 10.624L30.9054 10.0895C30.9626 8.07045 29.7444 6.63846 27.7207 6.90479C25.8732 7.14768 24.8107 8.72503 25.0405 10.4665C25.289 12.3627 26.7613 13.4505 28.7035 13.1945ZM27.8191 8.39024C28.4081 8.3124 28.892 8.71565 29.0148 9.28676L26.7079 9.5906C26.7726 8.89852 27.1843 8.4737 27.8191 8.39024Z"
        fill="white"
      />
      <path
        d="M34.3217 19.7402C34.4511 19.6624 34.5806 19.5818 34.71 19.5002L33.9372 17.4924H31.9548L33.2236 20.3704C33.594 20.1669 33.9616 19.9569 34.3217 19.7402Z"
        fill="white"
      />
      <path
        d="M37.3142 17.4924H36.774L36.4749 18.2238L35.3148 21.0606C35.3129 21.0616 35.312 21.0625 35.3101 21.0634C35.2623 21.0925 35.2164 21.1225 35.1685 21.1507C34.7465 21.4039 34.3189 21.6467 33.8875 21.8802L34.4699 23.2016L33.3933 25.9155H35.0194L38.0625 19.0313L38.7434 17.4914H37.3142V17.4924Z"
        fill="white"
      />
      <path
        d="M26.1799 23.3619C27.8951 22.8039 29.5916 22.1484 31.2177 21.387V15.5221H29.3656V18.2885C29.0092 17.6827 28.3687 17.3507 27.5613 17.3507C25.9108 17.3507 24.6288 18.6918 24.6288 20.5326C24.6298 21.8099 25.2487 22.8471 26.1799 23.3619ZM27.942 19.0359C28.6894 19.0359 29.2718 19.5817 29.3665 20.3301V20.7333C29.2718 21.4807 28.6894 22.0275 27.942 22.0275C27.1346 22.0275 26.5175 21.386 26.5175 20.5317C26.5175 19.6774 27.1346 19.0359 27.942 19.0359Z"
        fill="white"
      />
      <path
        d="M6.76791 26.0215C7.1646 26.0562 7.58285 26.0806 8.02173 26.0928H8.03392C8.93513 26.1321 9.97607 26.1303 11.123 26.0806C10.6503 25.606 9.95544 25.3904 9.49874 25.3303C8.4231 25.1897 7.45906 25.4448 6.76791 26.0215Z"
        fill="white"
      />
      <path
        d="M8.98284 29.9808C8.07695 29.8617 7.45426 29.1331 7.56586 28.2853C7.59305 28.0809 7.65963 27.8943 7.7581 27.7302C7.05664 27.7039 6.40394 27.6523 5.79907 27.5726C5.75875 27.7226 5.7278 27.8783 5.70623 28.0406C5.45491 29.9471 6.71529 31.3819 8.66869 31.6398C9.13945 31.7017 9.94782 31.6529 10.5217 31.2975L10.7646 29.45C10.2657 29.8749 9.62991 30.0662 8.98284 29.9808Z"
        fill="white"
      />
      <path
        d="M12.0306 27.6889C11.844 28.0274 11.7155 28.4091 11.6611 28.8245C11.4257 30.6138 12.6702 32.1659 14.5298 32.4115C16.3894 32.6563 17.993 31.4785 18.2284 29.6892C18.3597 28.6895 18.0343 27.7705 17.4013 27.1084C15.5642 27.3916 13.7477 27.5885 12.0306 27.6889ZM16.3688 29.4444C16.2572 30.2922 15.5614 30.8473 14.7492 30.7395C13.9493 30.6345 13.4204 29.918 13.532 29.0702C13.6436 28.2225 14.3394 27.6682 15.1394 27.7733C15.9524 27.8811 16.4804 28.5976 16.3688 29.4444Z"
        fill="white"
      />
    </svg>
  );
};

export const StyledLogoSpring = styled(LogoWithSpring)`
  margin-right: 1rem;
`;
