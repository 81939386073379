import './ClientMealPlanAndCycleList.scss';
import {
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  Button,
} from 'carbon-components-react';
import { Edit16 } from '@carbon/icons-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ClientDataInput } from '../../types/adminPortalApiSchema';
import { WithBlueBullet } from '../Icons/BlueBullet';

export type ClientMealPlanListItem = {
  text: string;
  date: string;
  value: string | number | undefined | null;
  hide?: boolean;
  cycleId: string;
  isDirty?: boolean;
};

type Props = {
  listItems: ClientMealPlanListItem[];
  pendingUserChanges: Partial<ClientDataInput>;
  editMealAndCycleModalCallback: (args: { cycleId: string }) => void;
};

export const ClientMealPlanAndCycleList = ({
  listItems,
  pendingUserChanges,
  editMealAndCycleModalCallback,
}: Props): ReactElement => {
  return (
    <StructuredListBody>
      {listItems
        .filter((elem) => !elem.hide)
        .map((param, index) => {
          const { text, value, cycleId, date } = param;
          const dirty =
            pendingUserChanges.mealPlanAndRecipePackForCycle?.find(
              (c) => c.cycleId === cycleId
            ) != null ||
            pendingUserChanges.cycleNumberAndAbilityForCycle?.find(
              (c) => c.cycleId === cycleId
            ) != null;
          return (
            <StructuredListRow key={index}>
              <StructuredListCellText>
                {dirty ? <WithBlueBullet leftWidth={5} copy={text} /> : text}
              </StructuredListCellText>
              <StructuredListCellText>{date}</StructuredListCellText>
              <StructuredListCellValue isDirty={dirty}>
                {value}
                <BottomPaddedButton
                  kind="ghost"
                  data-testid="edit-stat-icon"
                  iconDescription="icon"
                  renderIcon={Edit16}
                  onClick={(): void => {
                    editMealAndCycleModalCallback({ cycleId });
                  }}
                />
              </StructuredListCellValue>
            </StructuredListRow>
          );
        })}
    </StructuredListBody>
  );
};

export const StructuredListCellValue = styled(({ isDirty, ...rest }) => (
  <StructuredListCell {...rest} />
))`
  font-weight: bold;
  width: 10em;
  padding-top: 2em;
  color: ${(props): string => {
    if (props.invalid) return props.theme.warningRed;
    return props.isDirty ? props.theme.inspireCobalt : 'inherit';
  }};
`;

export const StructuredListCellName = styled((props) => (
  <StructuredListCell {...props} />
))`
  width: 10em;
  padding-top: 2em;
`;

export const StructuredListCellText = styled(StructuredListCell)`
  max-width: 3em;
  width: 7em;
  padding-top: 2em;
`;

export const BottomPaddedButton = styled((props) => <Button {...props} />)`
  margin-top: -1rem;
  float: right;
`;
