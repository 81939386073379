import React, { ReactElement, useState, useEffect } from 'react';
import './styles.scss';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { LoadingDialog } from '../Dialogs/Loading/Loading';

import { User } from './utils';
import { updateGroup, updateSuspendedAdmin } from './updateAdmin';
type Props = {
  user: User;
  hasBeenEdited: boolean;
  onChange: () => void;
};

export const UpdateAdminGroup = ({
  user,
  hasBeenEdited,
  onChange,
}: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  function handleChange() {
    // Here, we invoke the callback with the new value
    onChange();
  }
  const handleSaveChanges = (): void => {
    setLoading(false);
    setShowContinueDialog(true);
  };
  useEffect(() => {
    if (hasBeenEdited) {
      setShowConfirmationDialog(true);
    }
  }, [hasBeenEdited, onChange]);

  return (
    <div>
      {showConfirmationDialog && !isLoading && (
        <ConfirmationDialog
          headline={`Do you want to change the access for ${user.name}`}
          byline={`Are you sure you want to change ${user.email} from "${user.currentGroup}" to "${user.newGroup}"?`}
          dismissButtonText="Don't Save"
          confirmButtonText="Save"
          confirmButtonColor="blue"
          confirmButtonCallback={async (): Promise<void> => {
            setLoading(true);
            const { data } = await updateGroup({
              id: user.email,
              updatedGroup: user.newGroup,
            });

            setShowConfirmationDialog(false);
            if (data !== null) {
              setLoading(false);
              setShowContinueDialog(true);
            } else {
              setLoading(false);
              alert(
                `Unable to ${
                  !user.suspended ? 'Unsuspend' : 'Suspend'
                } admin user, please contact an administrator quoting adminId:${
                  user.id
                } to help resolve this.`
              );
            }
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
            handleChange();
          }}
          warning={false}
        />
      )}
      {isLoading && <LoadingDialog heading={'Saving your work'} />}
      {showContinueDialog && (
        <ContinueDialog
          headline={'Your changes have been saved'}
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
            handleChange();
          }}
        />
      )}
    </div>
  );
};
