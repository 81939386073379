import { CycleSummary } from '../types/adminPortalApiSchema';
import { queryAPI } from '../utils/queryApi';
import gql from 'fake-tag';

export async function fetchCycles(): Promise<{
  data: { cycles: CycleSummary[] };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    cycles: CycleSummary[];
    errors: [{ message: string }];
  }>(
    gql`
      query GetCycles {
        cycles {
          id
          ability
          cycleNumber
          workoutCount
        }
      }
    `
  );
}
