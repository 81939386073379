import React, { ReactElement, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, TextInput, Link } from 'carbon-components-react';
import './styles.scss';
import { Field, Form } from 'react-final-form';
import ClientDataTable, { ExtendedClient } from './ClientDataTable';

import { fetchClients } from './getClientsQuery';
import { Loader } from '../Loader/Loader';
import Joi from '@hapi/joi';
import validate from '../../utils/validate';
import styled from 'styled-components';
import { Search32 } from '@carbon/icons-react';
import { isUUID } from '../../utils/utils';

type SearchValues = {
  id: string | undefined;
  email: string | undefined;
};

export const ClientTable = (): ReactElement => {
  const [rows, setRows] = useState<ExtendedClient[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const id = searchParams.get('id');
  const initialValues = {
    ...(email ? { email } : {}),
    ...(id ? { id } : {}),
  };
  const searchClients = async ({ email, id }: SearchValues): Promise<void> => {
    try {
      setLoading(true);
      const res = await fetchClients({ email, id });
      const { data } = res;
      const ids = data?.clients.map((row) => row.id);
      const extendedRows = data?.clients.map((row: ExtendedClient, index) => {
        // This helps with the second call as in tests
        row.userId = isUUID(ids[index]) ? ids[index] : row.userId;
        row.id = index.toString();
        return row;
      }) as ExtendedClient[];

      setRows(extendedRows || []);
    } catch (e) {
      setRows([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (email || id) {
      searchClients({ email: email || '', id: id || '' });
    } else {
      setRows([]);
    }
  }, [email, id]);

  const updateSearchParams = ({ email, id }: SearchValues) => {
    if (email) {
      searchParams.set('email', email);
    } else {
      searchParams.delete('email');
    }
    if (id) {
      searchParams.set('id', id);
    } else {
      searchParams.delete('id');
    }
    history.push(`${currentPath}?${searchParams.toString()}`);
  };
  return (
    <Wrapper>
      <Form
        onSubmit={updateSearchParams}
        initialValues={initialValues}
        validate={(values) =>
          validate(
            values,
            Joi.object({
              email: Joi.string(),
              id: Joi.string(),
            })
              .min(1)
              .message('Search requires at least 1 parameter')
          )
        }
        render={({
          handleSubmit,
          submitSucceeded,
          hasValidationErrors,
          errors,
          form,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <Field name="email">
                {({ input, meta }) => (
                  <div className="bx--search bx--search--xl">
                    <Search32 className="bx--search-magnifier" />
                    <Input
                      {...input}
                      className="bx--search-input"
                      id="email"
                      invalid={meta.error && meta.touched}
                      invalidText={meta.error}
                      labelText="email"
                      hideLabel={true}
                      light={false}
                      placeholder="Enter Email address"
                      type="text"
                    />
                  </div>
                )}
              </Field>
              <Field name="id">
                {({ input, meta }) => (
                  <div className="bx--search bx--search--xl">
                    <Search32 className="bx--search-magnifier" />
                    <Input
                      {...input}
                      id="id"
                      className="bx--search-input"
                      invalid={meta.error && meta.touched}
                      invalidText={meta.error}
                      hideLabel={true}
                      labelText="id"
                      light={false}
                      placeholder="Enter User ID"
                      type="text"
                    />
                  </div>
                )}
              </Field>
              <SubmitButton disabled={loading} type="submit">
                Search
              </SubmitButton>
              <LinkContainer>
                <StyledLink
                  onClick={(e: React.MouseEvent<any>): void => {
                    e.preventDefault();
                    form.reset();
                    history.push('/clients');
                  }}
                >
                  Clear search
                </StyledLink>
              </LinkContainer>
            </FormWrapper>
            {hasValidationErrors && errors && errors[''] && (
              <ErrorText>{errors['']}</ErrorText>
            )}
            {loading ? (
              <Loader />
            ) : (
              <ClientDataTable
                rows={rows}
                submitSucceeded={submitSucceeded || !!(email || id)}
              />
            )}
          </form>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 2rem auto auto;
  .bx--data-table td {
    vertical-align: middle;
  }
`;

const FormWrapper = styled.div`
  display: inline-flex;
`;

const Input = styled((props) => <TextInput {...props} />)`
  width: 360px;
  margin-right: 50px;
`;

const ErrorText = styled.span`
  margin-top: 50px;
  display: block;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  width: 100px;
  cursor: pointer;
`;

const SubmitButton = styled((props) => <Button {...props} />)`
  min-height: 2rem;
  padding: 0px 2rem;
  background: ${(props): string => props.theme.inspireCobalt};
`;
