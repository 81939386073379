import React from 'react';

export const BodyCoachSpringLogo = () => {
  return (
    <svg
      width="108"
      height="100"
      viewBox="0 0 108 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.9861 99.13C61.0336 99.13 60.2209 98.4011 60.1392 97.4336C60.051 96.4123 60.8079 95.5115 61.8291 95.4233C85.7507 93.3549 103.79 82.5874 103.79 70.3772C103.79 63.9786 99.3348 58.1584 91.2463 53.9916C90.3347 53.5229 89.9756 52.4027 90.4465 51.4911C90.9152 50.5795 92.0353 50.2204 92.947 50.6913C102.334 55.5267 107.503 62.5187 107.503 70.3772C107.503 84.7611 88.4297 96.8509 62.1495 99.1214C62.0957 99.1279 62.0398 99.13 61.9861 99.13ZM47.8623 61.2395C56.6647 59.3668 69.4941 55.731 80.6336 49.0357C85.6196 46.0407 90.0379 42.0695 93.0738 37.8532C96.8149 32.6608 98.3393 27.2534 97.4858 22.2115C96.5634 16.7676 93.0244 11.902 87.2493 8.13938C81.1195 4.15102 72.7342 1.61824 63.0031 0.822719C52.2248 -0.0588061 40.2296 1.14953 27.3507 4.41332C26.3402 4.66918 25.7296 5.69476 25.9854 6.70529C26.2413 7.71582 27.2669 8.32644 28.2774 8.07058C40.7521 4.90999 52.3323 3.73605 62.6956 4.58318C71.8054 5.3271 79.5822 7.65347 85.1896 11.3043C90.0573 14.4756 93.0244 18.4661 93.7662 22.8458C95.2432 31.56 87.2579 40.6548 78.6899 45.8042C59.1329 57.5564 31.9304 60.9557 18.4194 60.3708H18.3914C10.748 60.1515 5.87806 58.4508 4.30852 55.4558C3.60115 54.1056 3.59685 52.3941 4.29777 50.7579C5.72111 47.4339 9.81268 44.6582 15.82 42.9381C16.8219 42.6522 17.4024 41.6072 17.1143 40.6053C16.8283 39.6034 15.7834 39.0229 14.7815 39.311C5.65661 41.9212 2.15631 46.1697 0.827567 49.2722C-0.322716 51.9555 -0.271114 54.8495 0.965171 57.2081C3.27004 61.605 8.93115 63.8733 18.2689 64.142C19.3611 64.1893 20.492 64.213 21.6574 64.213C29.4943 64.2087 38.8364 63.1595 47.8623 61.2395Z"
        fill="#00CFDA"
      />
      <path
        d="M47.184 54.9118C51.4841 54.9118 54.778 51.7555 54.778 47.6167C54.778 43.452 51.4841 40.3215 47.184 40.3215C42.8839 40.3215 39.59 43.452 39.59 47.6167C39.59 51.7534 42.8839 54.9118 47.184 54.9118ZM47.184 44.1873C49.061 44.1873 50.4779 45.658 50.4779 47.6167C50.4779 49.5754 49.0631 51.046 47.184 51.046C45.3328 51.046 43.918 49.5754 43.918 47.6167C43.918 45.658 45.3328 44.1873 47.184 44.1873Z"
        fill="#0053C7"
      />
      <path
        d="M27.0681 52.7596C27.8851 54.1485 29.3536 54.9097 31.2048 54.9097C34.9889 54.9097 37.9001 51.8351 37.9001 47.6145C37.9001 43.3961 34.9868 40.3193 31.2048 40.3193C29.3536 40.3193 27.8851 41.0805 27.0681 42.4694V36.1267H22.7959V54.5807H27.0681V52.7596ZM27.0681 47.1544C27.2852 45.4408 28.6204 44.1873 30.334 44.1873C32.1852 44.1873 33.6 45.6579 33.6 47.6167C33.6 49.5754 32.1852 51.046 30.334 51.046C28.6183 51.046 27.2852 49.7947 27.0681 48.0789V47.1544Z"
        fill="#0053C7"
      />
      <path
        d="M49.9596 62.3317C48.2869 62.1124 46.4722 62.3403 45.0553 63.0864L44.5909 66.6211C45.9304 65.8363 47.3387 65.5546 48.7427 65.7395C50.7401 66.0018 51.8796 67.1414 51.6775 68.6787L51.5743 69.4613C50.8476 68.7346 49.7124 68.1734 48.418 68.0036C45.6122 67.6337 43.3976 69.0184 43.0579 71.607C42.7204 74.1699 44.3867 76.3114 47.0312 76.6597C48.6223 76.8704 50.0499 76.2619 50.8283 75.1289L50.6068 76.8016L54.8446 77.3585L55.9433 69.0205C56.3948 65.5933 53.9265 62.8542 49.9596 62.3317ZM51.269 71.7834C51.0175 73.0669 49.9532 73.8603 48.7384 73.7012C47.6591 73.5593 46.9861 72.839 47.0914 72.0306C47.1989 71.22 48.0095 70.6954 49.1147 70.8416C49.8435 70.9384 50.5982 71.2566 51.2798 71.7038L51.269 71.7834Z"
        fill="#0053C7"
      />
      <path
        d="M66.4743 64.5054C61.9957 63.9163 58.3557 66.32 57.7773 70.7169C57.2011 75.088 60.0908 78.3776 64.5694 78.9689C65.6487 79.1108 67.502 78.999 68.8179 78.1841L69.3747 73.9485C68.2309 74.9224 66.7732 75.3632 65.2896 75.1675C63.2127 74.8945 61.785 73.2239 62.0409 71.2802C62.2967 69.3366 64.1071 68.0938 66.1862 68.3669C67.6697 68.5626 68.891 69.3 69.7467 70.5105L70.2971 66.3286C69.2156 65.1676 67.5536 64.6473 66.4743 64.5054Z"
        fill="#0053C7"
      />
      <path
        d="M81.1271 66.434C79.4544 66.2147 77.829 66.8791 76.9001 68.1003L77.6956 62.0608L73.4858 61.5061L71.1165 79.4978L75.3263 80.0525L76.2788 72.8197C76.5174 71.0115 77.786 69.9171 79.2716 70.1106C80.7552 70.3063 81.6625 71.5511 81.4346 73.2776L80.4542 80.7255L84.664 81.2802L85.7197 73.2647C86.2185 69.4656 84.3651 66.8619 81.1271 66.434Z"
        fill="#0053C7"
      />
      <path
        d="M28.2721 24.8711L30.4307 24.5873L31.0456 29.2551C31.5896 33.3832 34.2901 34.9227 37.4206 34.5099C38.3386 34.3895 39.4158 34.0283 40.3145 33.5531L39.8308 29.8829C39.3836 30.2442 38.745 30.6032 37.7474 30.7344C36.668 30.8763 35.4963 30.3173 35.2555 28.4833L34.6685 24.0305L38.9858 23.4629L38.5278 19.9819L34.2105 20.5495L33.7354 16.9331L29.4976 17.49L29.9728 21.1064L27.8141 21.3902L28.2721 24.8711Z"
        fill="#0053C7"
      />
      <path
        d="M49.1685 18.249C47.4957 18.4683 46.0982 19.5325 45.5155 20.9537L44.7953 15.4754L40.5854 16.0301L42.8796 33.4628L47.0894 32.9081L46.1369 25.6753C45.8983 23.8671 46.8421 22.4803 48.3257 22.2846C49.8092 22.089 51.0068 23.0565 51.2347 24.7851L52.2151 32.233L56.425 31.6782L55.3693 23.6628C54.8705 19.8593 52.4065 17.8232 49.1685 18.249Z"
        fill="#0053C7"
      />
      <path
        d="M65.8077 30.7946C68.2093 30.4785 69.9186 29.7045 70.9421 28.4983L70.4798 24.9894C69.1876 26.23 67.6976 27.004 66.1324 27.2083C63.7308 27.5243 62.1956 26.4923 61.5463 24.9013L70.8561 23.6757C70.9872 19.0466 68.1943 15.7635 63.5545 16.3741C59.3188 16.931 56.8828 20.5474 57.4096 24.54C57.9793 28.8875 61.3549 31.3815 65.8077 30.7946ZM63.7802 19.7798C65.1305 19.6013 66.2399 20.5259 66.5215 21.8353L61.2324 22.5319C61.3807 20.9451 62.3246 19.9712 63.7802 19.7798Z"
        fill="#0053C7"
      />
      <path
        d="M78.6887 45.802C78.9854 45.6235 79.2821 45.4386 79.5788 45.2516L77.8072 40.6483H73.262L76.171 47.2468C77.0203 46.7803 77.8631 46.2987 78.6887 45.802Z"
        fill="#0053C7"
      />
      <path
        d="M85.5498 40.6483H84.3114L83.6255 42.3253L80.9659 48.8293C80.9616 48.8314 80.9595 48.8336 80.9552 48.8357C80.8455 48.9024 80.7401 48.9712 80.6305 49.0357C79.663 49.6162 78.6825 50.1731 77.6935 50.7084L79.0287 53.7379L76.5604 59.9602H80.2886L87.2656 44.1766L88.8265 40.6461H85.5498V40.6483Z"
        fill="#0053C7"
      />
      <path
        d="M60.0219 54.1055C63.9544 52.8262 67.8438 51.3233 71.572 49.5775V36.131H67.3257V42.4737C66.5086 41.0848 65.0401 40.3236 63.1889 40.3236C59.4048 40.3236 56.4657 43.3982 56.4657 47.6188C56.4678 50.5472 57.8869 52.9251 60.0219 54.1055ZM64.0619 44.1873C65.7755 44.1873 67.1107 45.4386 67.3278 47.1544V48.0789C67.1107 49.7925 65.7755 51.046 64.0619 51.046C62.2107 51.046 60.7959 49.5753 60.7959 47.6166C60.7959 45.6579 62.2107 44.1873 64.0619 44.1873Z"
        fill="#0053C7"
      />
      <path
        d="M15.5156 60.2031C16.4251 60.2827 17.384 60.3386 18.3903 60.3665H18.4182C20.4844 60.4568 22.871 60.4525 25.5005 60.3386C24.4169 59.2506 22.8237 58.7561 21.7766 58.6185C19.3105 58.296 17.1002 58.8808 15.5156 60.2031Z"
        fill="#0053C7"
      />
      <path
        d="M20.5942 69.2807C18.5172 69.0076 17.0896 67.337 17.3454 65.3934C17.4078 64.9246 17.5605 64.4968 17.7862 64.1205C16.178 64.0603 14.6815 63.9421 13.2947 63.7593C13.2023 64.1033 13.1313 64.4602 13.0819 64.8322C12.5057 69.2033 15.3953 72.4929 19.8739 73.0841C20.9533 73.226 22.8066 73.1142 24.1224 72.2994L24.6793 68.0637C23.5355 69.0377 22.0777 69.4763 20.5942 69.2807Z"
        fill="#0053C7"
      />
      <path
        d="M27.5818 64.0259C27.154 64.8021 26.8594 65.6772 26.7347 66.6296C26.1951 70.732 29.0482 74.2903 33.3118 74.8536C37.5753 75.4148 41.2519 72.7143 41.7916 68.612C42.0926 66.32 41.3465 64.213 39.8953 62.695C35.6833 63.3444 31.5186 63.7959 27.5818 64.0259ZM37.528 68.0508C37.2722 69.9945 35.6768 71.2673 33.8149 71.0201C31.9809 70.7793 30.7682 69.1366 31.0241 67.193C31.28 65.2493 32.8753 63.9786 34.7093 64.2194C36.5734 64.4667 37.7839 66.1093 37.528 68.0508Z"
        fill="#0053C7"
      />
    </svg>
  );
};
