import './ClientMealPlanAndCycleSection.scss';
import {
  StructuredListCell,
  StructuredListWrapper,
  Button,
} from 'carbon-components-react';
import React, { ReactElement, useState } from 'react';
import { capitalize } from 'lodash';

import { HorizontalRule, Heading2 } from '../Theme/Theme';
import styled from 'styled-components';
import {
  ClientDetail,
  MealPlan,
  ClientDataInput,
  ExerciseAbility,
  ClientMealPlanAndRecipePackForCycleInput,
  ClientCycleNumberAndAbilityCycleInput,
  UserCycleStat,
} from '../../types/adminPortalApiSchema';
import { StyledBlueButton } from '../Button/Button';
import { UpdateUserArgs } from '../../pages/Client/Client';
import { EditMealPlanAndCycle } from '../Dialogs/EditMealPlanAndCycle/EditMealPlanAndCycle';
import spacetime from 'spacetime';
import {
  convertAbilityForEmail,
  convertFromEmailAbilityToAbility,
  orderCyclesByCreatedAtDate,
} from '../../utils/utils';
import { ClientSaveSection } from './ClientSaveSection';
import {
  ClientMealPlanAndCycleList,
  ClientMealPlanListItem,
} from './ClientMealPlanAndCycleList';
import { ExerciseAbilityForEmail } from '../../types/global';

type Props = {
  user: ClientDetail;
  hasBeenEdited: boolean;
  updateUser: (args: UpdateUserArgs) => void;
  updatePendingUserChanges: (args: Partial<ClientDataInput>) => void;
  pendingUserChanges: ClientDataInput;
  isValid: boolean;
};

export const ClientMealPlanAndCycleSection = ({
  user,
  hasBeenEdited,
  updateUser,
  updatePendingUserChanges,
  pendingUserChanges,
  isValid,
}: Props): ReactElement => {
  const userCycles = (user && user.cycles) ?? [];
  const [latestCycle, ...allCyclesExceptLatest] = orderCyclesByCreatedAtDate(
    userCycles.filter(({ cycleNumber }) => cycleNumber !== 0)
  );

  const defaultStateModal: {
    show: boolean;
    cycleId: string | null;
    mealPlan: MealPlan | null;
    recipePack: number | null;
    ability: ExerciseAbilityForEmail | null;
    cycleSeqNo: number | null;
  } = {
    show: false,
    cycleId: null,
    mealPlan: null,
    recipePack: null,
    cycleSeqNo: null,
    ability: null,
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const [showEditMealPlanAndCycle, setShowEditMealPlanAndCycle] = useState(
    defaultStateModal
  );

  const processCycle = (cycle: UserCycleStat): ClientMealPlanListItem => {
    const recipePack = dataToDisplay.getCycleRecipePack(cycle.cycleId);
    const mealPlan = dataToDisplay.getCycleMealPlan(cycle.cycleId);
    const ability = dataToDisplay.getCycleAbility(cycle.cycleId);
    const cycleNumber = dataToDisplay.getCycleSeqNo(cycle.cycleId);
    return {
      hide: !cycle.cycleNumber || recipePack === 0 || !mealPlan,
      text:
        ability === 'LIFESTYLE'
          ? `Adv+ cycle ${cycleNumber}`
          : `${capitalize(
              convertAbilityForEmail(ability as ExerciseAbility) as string
            ).substring(0, 3)} cycle ${cycleNumber}`,
      date: spacetime(cycle.cycleUpdatedAt)
        .format('{date-pad}/{iso-month}/{year}')
        .toString(),
      value: `${capitalize(mealPlan as string)}, Pack ${recipePack}`,
      cycleId: cycle.cycleId,
    };
  };

  const dataToDisplay = {
    currentCycleId: latestCycle?.cycleId,
    currentAbility:
      pendingUserChanges.currentAbilityAndCycle?.ability ||
      latestCycle?.ability,
    currentCycleNumber:
      pendingUserChanges.currentAbilityAndCycle?.cycleNumber ||
      latestCycle?.cycleNumber,
    getCycleCompletedDate: (cycleId: string): string =>
      [latestCycle]
        .concat(allCyclesExceptLatest)
        .find((c) => c.cycleId === cycleId)?.cycleUpdatedAt,
    getCycleMealPlan: (cycleId: string): MealPlan | undefined =>
      pendingUserChanges?.mealPlanAndRecipePackForCycle?.find(
        (c) => c.cycleId === cycleId
      )?.mealPlan ||
      (latestCycle &&
        [latestCycle]
          .concat(allCyclesExceptLatest)
          .find((c) => c.cycleId === cycleId)?.mealPlan),
    getCycleRecipePack: (cycleId: string): number =>
      pendingUserChanges?.mealPlanAndRecipePackForCycle?.find(
        (c) => c.cycleId === cycleId
      )?.recipePackNumber ||
      (latestCycle &&
        [latestCycle]
          .concat(allCyclesExceptLatest)
          .find((c) => c.cycleId === cycleId)?.recipePackNumber) ||
      0,
    getCycleSeqNo: (cycleId: string): number =>
      pendingUserChanges?.cycleNumberAndAbilityForCycle?.find(
        (c) => c.cycleId === cycleId
      )?.cycleNumber ||
      (latestCycle &&
        [latestCycle]
          .concat(allCyclesExceptLatest)
          .find((c) => c.cycleId === cycleId)?.cycleNumber) ||
      0,
    getCycleAbility: (cycleId: string): ExerciseAbility | undefined =>
      pendingUserChanges?.cycleNumberAndAbilityForCycle?.find(
        (c) => c.cycleId === cycleId
      )?.ability ||
      (latestCycle &&
        [latestCycle]
          .concat(allCyclesExceptLatest)
          .find((c) => c.cycleId === cycleId)?.ability),
  };

  const editMealAndCycleModal = ({ cycleId }: { cycleId: string }): void => {
    setShowEditMealPlanAndCycle({
      show: true,
      cycleId,
      mealPlan: dataToDisplay.getCycleMealPlan(cycleId) as MealPlan,
      recipePack: dataToDisplay.getCycleRecipePack(cycleId),
      ability: convertAbilityForEmail(
        dataToDisplay.getCycleAbility(cycleId) as ExerciseAbility
      ),
      cycleSeqNo: dataToDisplay.getCycleSeqNo(cycleId),
    });
  };

  return (
    <>
      {showEditMealPlanAndCycle?.cycleId &&
        showEditMealPlanAndCycle?.mealPlan &&
        showEditMealPlanAndCycle?.ability &&
        showEditMealPlanAndCycle?.cycleSeqNo &&
        showEditMealPlanAndCycle?.recipePack && (
          <EditMealPlanAndCycle
            headline={
              dataToDisplay.currentCycleId === showEditMealPlanAndCycle.cycleId
                ? 'Edit current plan'
                : 'Edit plan from library'
            }
            data={{
              cycleId: showEditMealPlanAndCycle.cycleId,
              mealPlan: showEditMealPlanAndCycle.mealPlan,
              ability: convertFromEmailAbilityToAbility(
                showEditMealPlanAndCycle.ability
              ),
              recipePack: showEditMealPlanAndCycle.recipePack,
              cycleSeqNo: showEditMealPlanAndCycle.cycleSeqNo,
            }}
            loading={isLoading}
            dismissButtonText="Cancel"
            confirmButtonText="OK"
            confirmButtonColor="blue"
            updateMealPlanCallback={async ({
              cycleId,
              mealPlan,
              recipePack,
              ability,
              cycleSeqNo,
              ensureRecipeAndCycleContentIsAvailable,
            }): Promise<void> => {
              // Check content exists for the mealPlan, recipePack, cycle number
              setLoading(true);
              const contentIsValid = await ensureRecipeAndCycleContentIsAvailable();

              setLoading(false);

              if (contentIsValid) {
                // Check to see if the original data is the same
                const originalCycle = [latestCycle]
                  .concat(allCyclesExceptLatest)
                  .find(({ cycleId: id }) => id === cycleId);

                // Deduplicate any other pending changes for the cycle's meal plan
                const dedupedPendingMealPlanChanges: ClientMealPlanAndRecipePackForCycleInput[] =
                  pendingUserChanges?.mealPlanAndRecipePackForCycle?.filter(
                    (change) => change.cycleId !== cycleId
                  ) || [];
                // Deduplicate any other pending changes for the cycle
                const dedupedPendingCycleChanges: ClientCycleNumberAndAbilityCycleInput[] =
                  pendingUserChanges?.cycleNumberAndAbilityForCycle?.filter(
                    (change) => change.cycleId !== cycleId
                  ) || [];
                const newPendingMealPlanChange: ClientMealPlanAndRecipePackForCycleInput[] = [
                  {
                    cycleId,
                    mealPlan,
                    recipePackNumber: recipePack,
                  },
                ];
                const newPendingCycleChange: ClientCycleNumberAndAbilityCycleInput[] = [
                  {
                    cycleId,
                    ability,
                    cycleNumber: cycleSeqNo,
                  },
                ];
                const recipeHasChanged =
                  originalCycle?.mealPlan !== mealPlan ||
                  originalCycle?.recipePackNumber !== recipePack;
                const cycleHasChanged =
                  originalCycle?.ability !== ability ||
                  originalCycle?.cycleNumber !== cycleSeqNo;
                updatePendingUserChanges({
                  mealPlanAndRecipePackForCycle: [
                    ...dedupedPendingMealPlanChanges,
                    ...(recipeHasChanged ? newPendingMealPlanChange : []),
                  ],
                  cycleNumberAndAbilityForCycle: [
                    ...dedupedPendingCycleChanges,
                    ...(cycleHasChanged ? newPendingCycleChange : []),
                  ],
                });

                // hide dialog box
                setShowEditMealPlanAndCycle(defaultStateModal);
              }
            }}
            dismissButtonCallback={(): void => {
              setShowEditMealPlanAndCycle(defaultStateModal);
            }}
          />
        )}
      <Wrapper>
        <Header>
          <Heading2>Plans</Heading2>
          <p>View and Edit current and previous plans.</p>
          <ClientSaveSection
            user={user}
            hasBeenEdited={hasBeenEdited}
            updateUser={updateUser}
            isValid={isValid}
            pendingUserChanges={pendingUserChanges}
            updatePendingUserChanges={updatePendingUserChanges}
            genericError="Failed to update meal plan or cycle"
          />
        </Header>
        <MealPlanWrapper>
          {dataToDisplay.currentCycleNumber && (
            <>
              <StructuredListTitle>Current plan</StructuredListTitle>
              <MealPlanContentWrapper>
                <StructuredListWrapper
                  style={{ width: '100%' }}
                  ariaLabel="Structured list"
                >
                  <ClientMealPlanAndCycleList
                    editMealAndCycleModalCallback={editMealAndCycleModal}
                    pendingUserChanges={pendingUserChanges}
                    listItems={[processCycle(latestCycle)]}
                  ></ClientMealPlanAndCycleList>
                </StructuredListWrapper>
              </MealPlanContentWrapper>
            </>
          )}
          {!!allCyclesExceptLatest.length && (
            <>
              <StructuredListTitle>Library</StructuredListTitle>
              <MealPlanContentWrapper>
                <StructuredListWrapper
                  style={{ width: '100%' }}
                  ariaLabel="Structured list"
                >
                  <ClientMealPlanAndCycleList
                    pendingUserChanges={pendingUserChanges}
                    editMealAndCycleModalCallback={editMealAndCycleModal}
                    listItems={allCyclesExceptLatest.map((cycle) => {
                      return processCycle(cycle);
                    })}
                  ></ClientMealPlanAndCycleList>
                </StructuredListWrapper>
              </MealPlanContentWrapper>
            </>
          )}
          {!dataToDisplay.currentCycleNumber &&
            !allCyclesExceptLatest.length &&
            'User has no meal plans'}
        </MealPlanWrapper>
      </Wrapper>
      <HorizontalRule />
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
`;

const MealPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
const MealPlanContentWrapper = styled.div`
  display: flex;
`;

const Header = styled.div`
  width: 50%;
`;

const StructuredListTitle = styled.span`
  font-weight: bold;
  padding-bottom: 20px;
`;

export const BottomPaddedButton = styled(Button)`
  margin-top: -1rem;
  float: right;
`;

export const StyledSaveChangesButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin-top: 16px;
  max-width: 178px;
`;

export const StructuredListCellValue = styled(({ isDirty, ...rest }) => (
  <StructuredListCell {...rest} />
))`
  font-weight: bold;
  width: 10em;
  padding-top: 2em;
  color: ${(props): string => {
    if (props.invalid) return props.theme.warningRed;
    return props.isDirty ? props.theme.inspireCobalt : 'inherit';
  }};
`;

export const StructuredListCellText = styled(StructuredListCell);
