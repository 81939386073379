import React from 'react';
import styled from 'styled-components';

type Props = {
  isWatched: boolean;
};

const WorkoutWatchedStatus: React.FC<Props> = ({ isWatched }) => {
  return (
    <StyledSpan style={{ backgroundColor: isWatched ? '#48D945' : '#E36E6E' }}>
      {isWatched ? 'WATCHED' : 'NOT WATCHED'}
    </StyledSpan>
  );
};

const StyledSpan = styled.span`
  padding: 6px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  width: 105px;
  text-align: center;
`;

export default WorkoutWatchedStatus;
