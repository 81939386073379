import { Loading } from 'carbon-components-react';
import React, { ReactElement } from 'react';
import './styles.scss';
import styled from 'styled-components';

export const Loader = (): ReactElement => {
  return (
    <Wrapper>
      <Loading
        active
        description="Active loading indicator"
        small={false}
        withOverlay={false}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .bx--loading__stroke {
    stroke: ${(props): string => props.theme.inspireCobalt};
  }
`;
