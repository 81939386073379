import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';

export async function deleteClient(id: string) {
  return queryAPI<{ deleteClient: boolean }>(
    gql`
      mutation DeleteClient($args: ID!) {
        deleteClient(id: $args)
      }
    `,
    { args: id }
  );
}
