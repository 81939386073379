import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledBlueButton, StyledButton } from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { TextInput, Select, SelectItem } from 'carbon-components-react';
import { Field, Form } from 'react-final-form';
import Joi from '@hapi/joi';
import validate from '../../../utils/validate';
import { AdminInput } from '../../../types/adminPortalApiSchema';
import { adminGroups } from '../../AdminTable/utils';
import { ValidationErrors } from 'final-form';

type Props = {
  headline: string;
  confirmButtonText: string;
  addNewAdminCallback: (adminInput: AdminInput) => Promise<void>;
  dismissButtonText: string;
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
};

export const NewAdminDialog = ({
  headline,
  confirmButtonText,
  addNewAdminCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const handleNewAdmin = (adminInput: AdminInput): void => {
    addNewAdminCallback(adminInput);
  };

  return (
    <NewAdminTakeover>
      <FormWrapper>
        <CloseIconImg
          data-testid={'dismiss-x-icon'}
          onClick={dismissDialog}
          src={CloseIcon}
          alt="Close this dialog"
        ></CloseIconImg>
        <Heading>{headline}</Heading>

        <Form
          onSubmit={handleNewAdmin}
          validate={(
            values
          ): ValidationErrors | Promise<ValidationErrors> | undefined =>
            validate(
              values,
              Joi.object({
                name: Joi.string().required(),
                email: Joi.string().required(),
                group: Joi.string()
                  .valid(...adminGroups)
                  .required(),
              })
            )
          }
          render={({ handleSubmit, submitting }): ReactElement => (
            <FormElement onSubmit={handleSubmit}>
              <Padder>
                <Field name="name">
                  {({ input, meta }): ReactElement => (
                    <TextInput
                      {...input}
                      id="name"
                      invalid={meta.error && meta.touched}
                      invalidText="You must enter a name"
                      labelText="Name"
                      light={false}
                      placeholder="Enter a name"
                      size={undefined}
                      type="text"
                      data-testid={'newadmin-name-field'}
                    />
                  )}
                </Field>
              </Padder>
              <Padder>
                <Field name="email">
                  {({ input, meta }): ReactElement => (
                    <TextInput
                      {...input}
                      id="email"
                      invalid={meta.error && meta.touched}
                      invalidText="You must enter an email address"
                      labelText="Email"
                      light={false}
                      placeholder="Enter an email address"
                      size={undefined}
                      type="email"
                      data-testid={'newadmin-email-field'}
                    />
                  )}
                </Field>
              </Padder>
              <Padder>
                <Field name="group">
                  {({ input, meta }): ReactElement => (
                    <StyledSelect
                      {...input}
                      invalid={meta.error && meta.touched}
                      invalidText="You must choose an Admin Group"
                      id="group"
                      name="group"
                      labelText="Admin Rights"
                      aria-label="Admin Rights"
                      data-testid={'newadmin-group-field'}
                    >
                      <SelectItem value="placeholder" text="Choose an option" />
                      {adminGroups.map((group, key) => (
                        <SelectItem key={key} value={group} text={group} />
                      ))}
                    </StyledSelect>
                  )}
                </Field>
              </Padder>
              <StyledConfirmButton
                type="submit"
                disabled={submitting}
                data-testid={'newadmin-confirm-button'}
              >
                {confirmButtonText}
              </StyledConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissButtonCallback}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </FormElement>
          )}
        />
      </FormWrapper>
    </NewAdminTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

export const StyledSelect = styled((props) => <Select {...props} />)`
  width: 100%;
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

const NewAdminTakeover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const FormElement = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 100%;
  height: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 36px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  width: 300px;
  height: auto;
  position: relative;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px;
`;
