import React, { ReactElement } from 'react';
import { StyledButton } from '../../components/Button/Button';
import { Heading } from '../../components/Theme/Theme';

export function Content(): ReactElement {
  return (
    <>
      <Heading>Content</Heading>

      <StyledButton
        href="/content/recipepacks"
        kind="secondary"
        size="default"
        tabIndex={0}
        type="button"
      >
        Recipe Packs
      </StyledButton>
      <StyledButton
        href="/content/invalidate"
        kind="secondary"
        size="default"
        tabIndex={0}
        type="button"
      >
        Invalidate Cache
      </StyledButton>
    </>
  );
}
