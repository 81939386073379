import React, { ReactElement, useState } from 'react';
import './styles.scss';
import {
  ClientDetail,
  ClientDataInput,
} from '../../types/adminPortalApiSchema';

import { updateClientData } from './updateClientData';
import { ErrorMessage } from '../Theme/Theme';
import { UpdateUserArgs } from '../../pages/Client/Client';
import { Loader } from '../Loader/Loader';
import { StyledSaveChangesButton } from './ClientProfileSection';
import styled from 'styled-components';
type Props = {
  user: ClientDetail;
  hasBeenEdited: boolean;
  updateUser: (args: UpdateUserArgs) => void;
  updatePendingUserChanges: (args: Partial<ClientDataInput>) => void;
  pendingUserChanges: ClientDataInput;
  isValid: boolean;
  genericError?: string;
};

export const ClientSaveSection = ({
  hasBeenEdited,
  updateUser,
  isValid,
  pendingUserChanges,
  updatePendingUserChanges,
  genericError = 'Something went wrong',
}: Props): ReactElement | null => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const handleSaveChanges = async (): Promise<void> => {
    if (hasBeenEdited) {
      setLoading(true);
      const { data, errors } = await updateClientData(pendingUserChanges);

      if (data) setLoading(false);
      if (data?.clientDataInput) {
        updatePendingUserChanges({
          currentAbilityAndCycle: null,
          mealPlanAndRecipePackForCycle: null,
          cycleNumberAndAbilityForCycle: null,
          daysToCheckIn: null,
          macros: null,
          name: null,
          initialWeight: null,
        });
        updateUser({
          update: data.clientDataInput,
        });
      }
      if (errors) {
        console.log({ errors });
        setLoading(false);
        setHasErrored(true);
      }
    }
  };

  return (
    <>
      <SaveChangesWrapper>
        {isLoading && <Loader />}
        {hasBeenEdited && !isLoading && isValid && (
          <>
            <h3>Before moving on, please save any changes </h3>
            <StyledSaveChangesButton
              data-testid={'save-changes-button'}
              onClick={handleSaveChanges}
              kind="secondary"
              size="default"
              tabIndex={0}
              type="button"
            >
              Save Changes
            </StyledSaveChangesButton>
            {hasErrored && <ErrorMessage>{genericError}</ErrorMessage>}
          </>
        )}
      </SaveChangesWrapper>
    </>
  );
};

const SaveChangesWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
`;
