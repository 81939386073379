import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';
import {
  UpdatedCycleBodyStats,
  UserCycleStatsInput,
} from '../../types/adminPortalApiSchema';

export async function updateClientStats(payload: UserCycleStatsInput) {
  return queryAPI<{ updateLatestUserStats: UpdatedCycleBodyStats }>(
    gql`
      mutation UserCycleStats($args: UserCycleStatsInput!) {
        updateLatestUserStats(userCycleStatsInput: $args) {
          cycleId
          macros {
            fat
            carb
            meat
            veg
            water
            poultry
            fish
            sauce
          }
          userId
          bodyStats {
            measurement
            measurementType
          }
          initialWeight {
            measurement
            timestamp
            id
          }
        }
      }
    `,
    {
      args: {
        userId: payload.userId,
        cycleId: payload.cycleId,
        bodyStats: payload.bodyStats,
      },
    }
  );
}
