import {
  MeasurementType,
  UserBodyStat,
} from '../../types/adminPortalApiSchema';

type UnitType = 'kg' | 'in' | 'toGrams' | 'toMm' | '?';

export const measurementHeaders: {
  [T in MeasurementType]: {
    title: string;
    unit: UnitType;
    min: number;
    max: number;
  };
} = {
  HIPS: {
    title: 'Hips',
    unit: 'in',
    min: 24,
    max: 75,
  },
  WAIST: {
    title: 'Waist',
    unit: 'in',
    min: 18,
    max: 70,
  },
  CHEST: {
    title: 'Chest',
    unit: 'in',
    min: 24,
    max: 80,
  },
  ARM_RIGHT: {
    title: 'Right Arm',
    unit: 'in',
    min: 8,
    max: 30,
  },
  ARM_LEFT: {
    title: 'Left Arm',
    unit: 'in',
    min: 8,
    max: 30,
  },
  LEG_RIGHT: {
    title: 'Right Leg',
    unit: 'in',
    min: 8,
    max: 40,
  },
  LEG_LEFT: {
    title: 'Left Leg',
    unit: 'in',
    min: 8,
    max: 40,
  },
  WEIGHT: {
    title: 'Weight',
    unit: 'kg',
    min: 40,
    max: 175,
  },
};

export const convertToUnit = (value: number, unit: UnitType): number =>
  ({
    in: (i: number): number => parseFloat((i / 25.4).toFixed(1)),
    kg: (i: number): number => parseFloat((i / 1000).toFixed(2)),
    toGrams: (i: number): number => i * 1000,
    toMm: (i: number): number => parseFloat((i * 25.4).toFixed()),
    '?': (i: number): number => i,
  }[unit](value));

export const getMeasurementTitle = (
  measurementType: MeasurementType
): string => {
  if (measurementHeaders[measurementType]) {
    return measurementHeaders[measurementType].title;
  }

  return measurementType;
};

export const getMeasurementUnit = (
  measurementType: MeasurementType
): UnitType => {
  if (measurementHeaders[measurementType]) {
    return measurementHeaders[measurementType].unit;
  }

  return '?';
};

export const getMeasurementLimits = (
  measurementType: MeasurementType
): { min: number; max: number } => {
  return {
    min: measurementHeaders[measurementType].min,
    max: measurementHeaders[measurementType].max,
  };
};

export const sortBodyStats = (a: UserBodyStat, b: UserBodyStat): number => {
  const order: MeasurementType[] = [
    'WEIGHT',
    'CHEST',
    'HIPS',
    'WAIST',
    'ARM_RIGHT',
    'ARM_LEFT',
    'LEG_RIGHT',
    'LEG_LEFT',
  ];
  for (const key of order) {
    if (a.measurementType === key) {
      return -1;
    }
    if (b.measurementType === key) {
      return 1;
    }
  }
  return 0;
};
