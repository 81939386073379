import React from 'react';
import { ClientTable } from '../components/ClientsTable/ClientTable';
import { Heading } from '../components/Theme/Theme';

export function Clients() {
  return (
    <>
      <Heading>Clients</Heading>
      <div>
        Here you can find all the clients who have subscribed or unsubscribed to
        The Body Coach program
      </div>

      <ClientTable />
    </>
  );
}
