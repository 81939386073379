import React, { ReactElement } from 'react';
import styled from 'styled-components';

export const OpenNow = ({ copy }: { copy?: string }): ReactElement => {
  return (
    <>
      <OpenNowBadge>{'Open now'}</OpenNowBadge>
      {copy && <OpenNowCopy>{`${copy}`}</OpenNowCopy>}
    </>
  );
};

const OpenNowBadge = styled.span`
  padding: 6px 8px;
  background: rgba(167, 240, 186, 1);
  color: #0e6027;
  border-radius: 18px;
  margin-top: 8px;
  max-width: 6em;
`;
const OpenNowCopy = styled.span`
  margin-left: 4px;
  font-weight: initial;
`;
