import { queryAPI } from '../../utils/queryApi';

import gql from 'fake-tag';

import { Admin, AdminInput } from '../../types/adminPortalApiSchema';

export async function createAdmin(
  createAdminInput: AdminInput
): Promise<{ data: { createAdminInput: Admin } }> {
  return queryAPI<{ createAdminInput: Admin }>(
    gql`
      mutation CreateAdmin($createAdminInput: AdminInput!) {
        createAdmin(createAdminInput: $createAdminInput) {
          email
          id
          name
          group
          status
        }
      }
    `,
    { createAdminInput }
  );
}
