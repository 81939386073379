import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import './styles.scss';
import { Heading2 } from '../Theme/Theme';
import { ClientDetail } from '../../types/adminPortalApiSchema';
import { StyledBlueButton } from '../Button/Button';
import { ConfirmationDialog } from '../Dialogs/Confirmation/Confirmation';
import { ContinueDialog } from '../Dialogs/Continue/Continue';
import { deleteClient } from './deleteClient';

type Props = {
  user: ClientDetail;
};

export const ClientDeleteSection = ({ user }: Props): ReactElement | null => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showContinueDialog, setShowContinueDialog] = useState(false);
  return (
    <ClientDeleteWrapper>
      <div>
        <Heading2>Delete account</Heading2>
        <p>
          Delete all of the client&apos;s details from The Body Coach, once
          deleted all data is unretrievable
        </p>
      </div>
      <StyledDeleteClientButton
        data-testid={'delete-client-button'}
        onClick={(): void => {
          setShowConfirmationDialog(true);
        }}
        kind="secondary"
        size="default"
        tabIndex={0}
        type="button"
      >
        Delete clients data
      </StyledDeleteClientButton>
      {showConfirmationDialog && (
        <ConfirmationDialog
          headline={`Are you sure you want to delete ${user.name}?`}
          byline="After deleting the account, the account will be destroyed, so the account can not be restored."
          dismissButtonText="Cancel"
          confirmButtonText="Delete account"
          confirmButtonColor="red"
          confirmButtonCallback={async (): Promise<void> => {
            return deleteClient(user.id)
              .then((res) => {
                const success = res?.data?.deleteClient;
                if (success === true) {
                  setShowConfirmationDialog(false);
                  setShowContinueDialog(true);
                } else {
                  throw new Error('Received a response, but was not success.');
                }
              })
              .catch((err) => {
                console.log({ err });
                setShowConfirmationDialog(false);
                alert(
                  `Deleting this account failed, please contact an administrator to help resolve this, quoting User ID: ${user.id}.`
                );
              });
          }}
          dismissButtonCallback={(): void => {
            setShowConfirmationDialog(false);
          }}
          warning={true}
        ></ConfirmationDialog>
      )}
      {showContinueDialog && (
        <ContinueDialog
          headline={`${user.name}'s account has been deleted`}
          continueToPath="/clients/"
          continueButtonText="Continue"
          continueButtonCallback={(): void => {
            setShowContinueDialog(false);
          }}
        ></ContinueDialog>
      )}
    </ClientDeleteWrapper>
  );
};

const ClientDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  margin-top: 70px;
`;

export const StyledDeleteClientButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  width: 178px;
  height: 48px;
`;
