import { queryAPI } from '../../utils/queryApi';
import gql from 'fake-tag';

export async function exportClientData(id: string) {
  return queryAPI<{ exportClientData: boolean }>(
    gql`
      mutation ExportClientData($args: ID!) {
        exportClientData(id: $args)
      }
    `,
    { args: id }
  );
}
